import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
// @ts-ignore
import { MIN_ASSUMED_BANDWIDTH_BPS } from '../../../../../modules/API/constants';
import { setAssumedBandwidthBps as saveAssumedBandwidthBps } from '../../../base/conference/actions';
import { IconInfoCircle } from '../../../base/icons/svg';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import Dialog from '../../../base/ui/components/web/Dialog';
import Input from '../../../base/ui/components/web/Input';
const useStyles = makeStyles()(theme => {
    return {
        content: {
            color: theme.palette.text01
        },
        info: {
            background: theme.palette.ui01,
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text02,
            marginTop: theme.spacing(2)
        },
        possibleValues: {
            margin: 0,
            paddingLeft: theme.spacing(4)
        }
    };
});
/**
 * Bandwidth settings dialog component.
 *
 * @returns {ReactElement}
 */
const BandwidthSettingsDialog = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showAssumedBandwidthInfo, setShowAssumedBandwidthInfo] = useState(false);
    const currentAssumedBandwidthBps = useSelector((state) => state['features/base/conference'].assumedBandwidthBps);
    const [assumedBandwidthBps, setAssumedBandwidthBps] = useState(currentAssumedBandwidthBps === MIN_ASSUMED_BANDWIDTH_BPS
        || currentAssumedBandwidthBps === undefined
        ? ''
        : currentAssumedBandwidthBps);
    /**
     * Changes the assumed bandwidth bps.
     *
     * @param {string} value - The key event to handle.
     *
     * @returns {void}
     */
    const onAssumedBandwidthBpsChange = useCallback((value) => {
        setAssumedBandwidthBps(value);
    }, [setAssumedBandwidthBps]);
    /**
     * Persists the assumed bandwidth bps.
     *
     * @param {string} value - The key event to handle.
     *
     * @returns {void}
     */
    const onAssumedBandwidthBpsSave = useCallback(() => {
        if (assumedBandwidthBps !== currentAssumedBandwidthBps) {
            dispatch(saveAssumedBandwidthBps(Number(assumedBandwidthBps === '' ? MIN_ASSUMED_BANDWIDTH_BPS : assumedBandwidthBps)));
        }
    }, [assumedBandwidthBps, currentAssumedBandwidthBps, dispatch, saveAssumedBandwidthBps]);
    /**
     * Validates the assumed bandwidth bps.
     *
     * @param {KeyboardEvent<any>} e - The key event to handle.
     *
     * @returns {void}
     */
    const onAssumedBandwidthBpsKeyPress = useCallback((e) => {
        const isValid = (e.charCode !== 8 && e.charCode === 0) || (e.charCode >= 48 && e.charCode <= 57);
        if (!isValid) {
            e.preventDefault();
        }
    }, []);
    /**
     * Callback invoked to hide or show the possible values
     * of the assumed bandwidth setting.
     *
     * @returns {void}
     */
    const toggleInfoPanel = useCallback(() => {
        setShowAssumedBandwidthInfo(!showAssumedBandwidthInfo);
    }, [setShowAssumedBandwidthInfo, showAssumedBandwidthInfo]);
    return (React.createElement(Dialog, { onSubmit: onAssumedBandwidthBpsSave, titleKey: 'bandwidthSettings.title' },
        React.createElement("div", { className: classes.content },
            React.createElement(Input, { bottomLabel: t('bandwidthSettings.assumedBandwidthBpsWarning'), icon: IconInfoCircle, iconClick: toggleInfoPanel, id: 'setAssumedBandwidthBps', label: t('bandwidthSettings.setAssumedBandwidthBps'), minValue: 0, name: 'assumedBandwidthBps', onChange: onAssumedBandwidthBpsChange, onKeyPress: onAssumedBandwidthBpsKeyPress, placeholder: t('bandwidthSettings.assumedBandwidthBps'), type: 'number', value: assumedBandwidthBps }),
            showAssumedBandwidthInfo && (React.createElement("div", { className: classes.info },
                React.createElement("span", null,
                    t('bandwidthSettings.possibleValues'),
                    ":"),
                React.createElement("ul", { className: classes.possibleValues },
                    React.createElement("li", null,
                        React.createElement("b", null, t('bandwidthSettings.leaveEmpty')),
                        " ",
                        t('bandwidthSettings.leaveEmptyEffect')),
                    React.createElement("li", null,
                        React.createElement("b", null, "0"),
                        " ",
                        t('bandwidthSettings.zeroEffect')),
                    React.createElement("li", null,
                        React.createElement("b", null, t('bandwidthSettings.customValue')),
                        " ",
                        t('bandwidthSettings.customValueEffect'))))))));
};
export default BandwidthSettingsDialog;
