import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPollEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { getParticipantDisplayName } from '../../base/participants/functions';
import { useBoundSelector } from '../../base/util/hooks';
import { editPoll, registerVote, setVoteChanging } from '../actions';
import { COMMAND_ANSWER_POLL, COMMAND_NEW_POLL } from '../constants';
/**
 * Higher Order Component taking in a concrete PollAnswer component and
 * augmenting it with state/behavior common to both web and native implementations.
 *
 * @param {React.AbstractComponent} Component - The concrete component.
 * @returns {React.AbstractComponent}
 */
const AbstractPollAnswer = (Component) => (props) => {
    const { pollId, setCreateMode } = props;
    const conference = useSelector((state) => state['features/base/conference'].conference);
    const poll = useSelector((state) => state['features/polls'].polls[pollId]);
    const { answers, lastVote, question, senderId } = poll;
    const [checkBoxStates, setCheckBoxState] = useState(() => {
        if (lastVote !== null) {
            return [...lastVote];
        }
        return new Array(answers.length).fill(false);
    });
    const participantName = useBoundSelector(getParticipantDisplayName, senderId);
    const setCheckbox = useCallback((index, state) => {
        const newCheckBoxStates = [...checkBoxStates];
        newCheckBoxStates[index] = state;
        setCheckBoxState(newCheckBoxStates);
        sendAnalytics(createPollEvent('vote.checked'));
    }, [checkBoxStates]);
    const dispatch = useDispatch();
    const submitAnswer = useCallback(() => {
        conference.sendMessage({
            type: COMMAND_ANSWER_POLL,
            pollId,
            answers: checkBoxStates
        });
        sendAnalytics(createPollEvent('vote.sent'));
        dispatch(registerVote(pollId, checkBoxStates));
        return false;
    }, [pollId, checkBoxStates, conference]);
    const sendPoll = useCallback(() => {
        conference?.sendMessage({
            type: COMMAND_NEW_POLL,
            pollId,
            question,
            answers: answers.map(answer => answer.name)
        });
        dispatch(editPoll(pollId, false));
    }, [conference, question, answers]);
    const skipAnswer = useCallback(() => {
        dispatch(registerVote(pollId, null));
        sendAnalytics(createPollEvent('vote.skipped'));
    }, [pollId]);
    const skipChangeVote = useCallback(() => {
        dispatch(setVoteChanging(pollId, false));
    }, [dispatch, pollId]);
    const { t } = useTranslation();
    return (React.createElement(Component, { checkBoxStates: checkBoxStates, creatorName: participantName, poll: poll, pollId: pollId, sendPoll: sendPoll, setCheckbox: setCheckbox, setCreateMode: setCreateMode, skipAnswer: skipAnswer, skipChangeVote: skipChangeVote, submitAnswer: submitAnswer, t: t }));
};
export default AbstractPollAnswer;
