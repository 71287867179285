import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { RECORD_TYPE } from '../../constants';
const useStyles = makeStyles()(theme => {
    return {
        recordItem: {
            display: 'flex',
            alignItems: 'center'
        },
        recordTypeIcon: {
            borderRadius: theme.shape.borderRadius,
            height: '40px',
            marginRight: '16px',
            width: '40px'
        },
        recordDetails: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-around',
            overflow: 'hidden',
            padding: '12px 0',
            textOverflow: 'ellipsis'
        },
        recordName: {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        recordType: {
            fontSize: '13px',
            fontWeight: 400,
            lineHeight: '18px'
        }
    };
});
/**
 * Component to render Record data.
 *
 * @param {IProps} props - The props of the component.
 * @returns {React$Element<any>}
 */
export const RecordItem = ({ id, name, 
// eslint-disable-next-line @typescript-eslint/no-empty-function
onClick = () => { }, type }) => {
    const { t } = useTranslation();
    const Icon = RECORD_TYPE[type].icon;
    const { classes } = useStyles();
    return (React.createElement("li", { className: classes.recordItem, key: `record-${id}`, onClick: onClick, title: name },
        React.createElement("div", { className: classes.recordTypeIcon }, Icon && React.createElement(Icon, null)),
        React.createElement("div", { className: classes.recordDetails },
            React.createElement("div", { className: classes.recordName, key: name }, name),
            React.createElement("div", { className: classes.recordType, key: type }, t(RECORD_TYPE[type ?? ''].label)))));
};
