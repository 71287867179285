import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { withPixelLineHeight } from '../../../../base/styles/functions.web';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            display: 'flex',
            padding: '10px',
            alignItems: 'center',
            backgroundColor: theme.palette.action03,
            '&:hover': {
                backgroundColor: theme.palette.action03Hover
            }
        },
        flag: {
            marginRight: theme.spacing(2)
        },
        text: {
            color: theme.palette.text01,
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    };
});
const CountryRow = ({ country, onEntryClick }) => {
    const { classes, cx } = useStyles();
    const _onClick = () => {
        onEntryClick(country);
    };
    return (React.createElement("div", { className: classes.container, 
        // eslint-disable-next-line react/jsx-no-bind
        onClick: _onClick },
        React.createElement("div", { className: cx(classes.flag, 'iti-flag', country.code) }),
        React.createElement("div", { className: classes.text }, `${country.name} (+${country.dialCode})`)));
};
export default CountryRow;
