import { createOpenWhiteboardEvent } from '../analytics/AnalyticsEvents';
import { sendAnalytics } from '../analytics/functions';
import { UPDATE_CONFERENCE_METADATA } from '../base/conference/actionTypes';
import { getCurrentConference } from '../base/conference/functions';
import MiddlewareRegistry from '../base/redux/MiddlewareRegistry';
import StateListenerRegistry from '../base/redux/StateListenerRegistry';
import { SET_WHITEBOARD_OPEN } from './actionTypes';
import { notifyWhiteboardLimit, resetWhiteboard, restrictWhiteboard, setWhiteboardOpen, setupWhiteboard } from './actions';
import { WHITEBOARD_ID } from './constants';
import { generateCollabServerUrl, isWhiteboardOpen, shouldEnforceUserLimit, shouldNotifyUserLimit } from './functions';
MiddlewareRegistry.register((store) => next => action => {
    const state = store.getState();
    switch (action.type) {
        case SET_WHITEBOARD_OPEN: {
            const enforceUserLimit = shouldEnforceUserLimit(state);
            const notifyUserLimit = shouldNotifyUserLimit(state);
            if (action.isOpen && !enforceUserLimit && !notifyUserLimit) {
                sendAnalytics(createOpenWhiteboardEvent());
                return next(action);
            }
            break;
        }
        case UPDATE_CONFERENCE_METADATA: {
            const { metadata } = action;
            if (metadata?.[WHITEBOARD_ID]) {
                store.dispatch(setupWhiteboard({
                    collabDetails: metadata[WHITEBOARD_ID].collabDetails,
                    collabServerUrl: generateCollabServerUrl(store.getState())
                }));
                store.dispatch(setWhiteboardOpen(true));
            }
            break;
        }
    }
    return next(action);
});
/**
 * Set up state change listener to perform maintenance tasks when the conference
 * is left or failed, e.g. Disable the whiteboard if it's left open.
 */
StateListenerRegistry.register(state => getCurrentConference(state), (conference, { dispatch }, previousConference) => {
    if (conference !== previousConference) {
        dispatch(resetWhiteboard());
    }
});
/**
 * Set up state change listener to limit whiteboard access.
 */
StateListenerRegistry.register(state => shouldEnforceUserLimit(state), (enforceUserLimit, { dispatch, getState }) => {
    if (isWhiteboardOpen(getState()) && enforceUserLimit) {
        dispatch(restrictWhiteboard());
    }
});
/**
 * Set up state change listener to notify about whiteboard usage.
 */
StateListenerRegistry.register(state => shouldNotifyUserLimit(state), (notifyUserLimit, { dispatch, getState }, prevNotifyUserLimit) => {
    if (isWhiteboardOpen(getState()) && notifyUserLimit && !prevNotifyUserLimit) {
        dispatch(notifyWhiteboardLimit());
    }
});
