import { connect } from 'react-redux';
import { createToolbarEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { translate } from '../../base/i18n/functions';
import { IconShareDoc } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
import { setOverflowMenuVisible } from '../../toolbox/actions.web';
import { toggleDocument } from '../actions';
/**
 * Implements an {@link AbstractButton} to open the chat screen on mobile.
 */
class SharedDocumentButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.documentOpen';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.documentClose';
        this.icon = IconShareDoc;
        this.label = 'toolbar.documentOpen';
        this.toggledLabel = 'toolbar.documentClose';
        this.tooltip = 'toolbar.documentOpen';
        this.toggledTooltip = 'toolbar.documentClose';
    }
    /**
     * Handles clicking / pressing the button, and opens / closes the appropriate dialog.
     *
     * @private
     * @returns {void}
     */
    _handleClick() {
        const { _editing, dispatch } = this.props;
        sendAnalytics(createToolbarEvent('toggle.etherpad', {
            enable: !_editing
        }));
        dispatch(toggleDocument());
        dispatch(setOverflowMenuVisible(false));
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._editing;
    }
}
/**
 * Maps part of the redux state to the component's props.
 *
 * @param {Object} state - The redux store/state.
 * @param {Object} ownProps - The properties explicitly passed to the component
 * instance.
 * @returns {Object}
 */
function _mapStateToProps(state, ownProps) {
    const { documentUrl, editing } = state['features/etherpad'];
    const { visible = Boolean(documentUrl) } = ownProps;
    return {
        _editing: editing,
        visible
    };
}
export default translate(connect(_mapStateToProps)(SharedDocumentButton));
