import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Dialog from '../../../base/ui/components/web/Dialog';
import { getWhiteboardConfig } from '../../functions';
/**
 * Component that renders the whiteboard user limit dialog.
 *
 * @returns {JSX.Element}
 */
const WhiteboardLimitDialog = () => {
    const { t } = useTranslation();
    const { limitUrl } = useSelector(getWhiteboardConfig);
    return (React.createElement(Dialog, { cancel: { hidden: true }, ok: { hidden: true }, titleKey: t('dialog.whiteboardLimitTitle') },
        React.createElement("span", null, t('dialog.whiteboardLimitContent')),
        limitUrl && (React.createElement("span", null,
            ` ${t('dialog.whiteboardLimitReference')} `,
            React.createElement("a", { href: limitUrl, rel: 'noopener noreferrer', target: '_blank' }, t('dialog.whiteboardLimitReferenceUrl')),
            "."))));
};
export default WhiteboardLimitDialog;
