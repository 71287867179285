import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUnreadPollCount } from '../../../polls/functions';
import { getUnreadCount } from '../../functions';
/**
 * Implements a React {@link Component} which displays a count of the number of
 * unread chat messages.
 *
 * @augments Component
 */
class ChatCounter extends Component {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        return (React.createElement("span", { className: 'badge-round' },
            React.createElement("span", null, !this.props._isOpen
                && (this.props._count || null))));
    }
}
/**
 * Maps (parts of) the Redux state to the associated {@code ChatCounter}'s
 * props.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     _count: number
 * }}
 */
function _mapStateToProps(state) {
    const { isOpen } = state['features/chat'];
    return {
        _count: getUnreadCount(state) + getUnreadPollCount(state),
        _isOpen: isOpen
    };
}
export default connect(_mapStateToProps)(ChatCounter);
