import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import TimelineAxis from './TimelineAxis';
const useStyles = makeStyles()(theme => {
    return {
        labels: {
            padding: '22px 0 7px 0',
            height: 20,
            '& .avatar-placeholder': {
                width: '32px',
                marginRight: theme.spacing(3)
            }
        }
    };
});
const SpeakerStatsLabels = (props) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const nameTimeClass = `name-time${props.showFaceExpressions ? ' expressions-on' : ''}`;
    return (React.createElement("div", { className: `row ${classes.labels}` },
        React.createElement("div", { className: 'avatar-placeholder' }),
        React.createElement("div", { className: nameTimeClass },
            React.createElement("div", null, t('speakerStats.name')),
            React.createElement("div", null, t('speakerStats.speakerTime'))),
        props.showFaceExpressions && React.createElement(TimelineAxis, null)));
};
export default SpeakerStatsLabels;
