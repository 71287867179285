import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import AbstractPageReloadOverlay, { abstractMapStateToProps } from './AbstractPageReloadOverlay';
import OverlayFrame from './OverlayFrame';
/**
 * Implements a React Component for page reload overlay. Shown before the
 * conference is reloaded. Shows a warning message and counts down towards the
 * reload.
 */
class PageReloadOverlay extends AbstractPageReloadOverlay {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { isNetworkFailure, t } = this.props;
        const { message, timeLeft, title } = this.state;
        return (React.createElement(OverlayFrame, { isLightOverlay: isNetworkFailure },
            React.createElement("div", { "aria-describedby": 'reload_overlay_text', "aria-labelledby": 'reload_overlay_title', className: 'inlay', role: 'dialog' },
                React.createElement("span", { className: 'reload_overlay_title', id: 'reload_overlay_title', role: 'heading' }, t(title)),
                React.createElement("span", { className: 'reload_overlay_text', id: 'reload_overlay_text' }, t(message, { seconds: timeLeft })),
                this._renderProgressBar(),
                this._renderButton())));
    }
}
export default translate(connect(abstractMapStateToProps)(PageReloadOverlay));
