export const SPEAKER_STATS_RELOAD_INTERVAL = 1000;
export const DISPLAY_SWITCH_BREAKPOINT = 600;
export const MOBILE_BREAKPOINT = 480;
export const THRESHOLD_FIXED_AXIS = 10000;
export const MINIMUM_INTERVAL = 4000;
export const SCROLL_RATE = 500;
export const MIN_HANDLER_WIDTH = 30;
export const TIMELINE_COLORS = {
    happy: '#F3AD26',
    neutral: '#676767',
    sad: '#539EF9',
    surprised: '#BC72E1',
    angry: '#F35826',
    fearful: '#3AC8C8',
    disgusted: '#65B16B',
    'no-detection': '#FFFFFF00'
};
export const CLEAR_TIME_BOUNDARY_THRESHOLD = 1000;
