/**
 * The type of the action which signals a tooltip is being displayed.
 *
 * {
 *     type: SHOW_TOOLTIP,
 *     content: string
 * }.
 */
export const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
/**
 * The type of the action which signals a tooltip should be hidden.
 *
 * {
 *     type: SHOW_TOOLTIP,
 *     content: string
 * }.
 */
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';
