import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { isMobileBrowser } from '../../../base/environment/utils';
import { isScreenShareParticipantById } from '../../../base/participants/functions';
import ConnectionIndicator from '../../../connection-indicator/components/web/ConnectionIndicator';
import { STATS_POPOVER_POSITION, THUMBNAIL_TYPE } from '../../constants';
import { getIndicatorsTooltipPosition } from '../../functions.web';
import PinnedIndicator from './PinnedIndicator';
import RaisedHandIndicator from './RaisedHandIndicator';
import StatusIndicators from './StatusIndicators';
import VideoMenuTriggerButton from './VideoMenuTriggerButton';
const useStyles = makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            '& > *:not(:last-child)': {
                marginRight: '4px'
            }
        }
    };
});
const ThumbnailTopIndicators = ({ disableConnectionIndicator, hidePopover, indicatorsClassName, isHovered, local, participantId, popoverVisible, showPopover, thumbnailType }) => {
    const { classes: styles, cx } = useStyles();
    const _isMobile = isMobileBrowser();
    const { NORMAL = 16 } = interfaceConfig.INDICATOR_FONT_SIZES || {};
    const _indicatorIconSize = NORMAL;
    const _connectionIndicatorAutoHideEnabled = Boolean(useSelector((state) => state['features/base/config'].connectionIndicators?.autoHide) ?? true);
    const _connectionIndicatorDisabled = _isMobile || disableConnectionIndicator
        || Boolean(useSelector((state) => state['features/base/config'].connectionIndicators?.disabled));
    const showConnectionIndicator = isHovered || !_connectionIndicatorAutoHideEnabled;
    const isVirtualScreenshareParticipant = useSelector((state) => isScreenShareParticipantById(state, participantId));
    if (isVirtualScreenshareParticipant) {
        return (React.createElement("div", { className: styles.container }, !_connectionIndicatorDisabled
            && React.createElement(ConnectionIndicator, { alwaysVisible: showConnectionIndicator, enableStatsDisplay: true, iconSize: _indicatorIconSize, participantId: participantId, statsPopoverPosition: STATS_POPOVER_POSITION[thumbnailType] })));
    }
    const tooltipPosition = getIndicatorsTooltipPosition(thumbnailType);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.container },
            React.createElement(PinnedIndicator, { iconSize: _indicatorIconSize, participantId: participantId, tooltipPosition: tooltipPosition }),
            !_connectionIndicatorDisabled
                && React.createElement(ConnectionIndicator, { alwaysVisible: showConnectionIndicator, enableStatsDisplay: true, iconSize: _indicatorIconSize, participantId: participantId, statsPopoverPosition: STATS_POPOVER_POSITION[thumbnailType] }),
            React.createElement(RaisedHandIndicator, { iconSize: _indicatorIconSize, participantId: participantId, tooltipPosition: tooltipPosition }),
            thumbnailType !== THUMBNAIL_TYPE.TILE && (React.createElement("div", { className: cx(indicatorsClassName, 'top-indicators') },
                React.createElement(StatusIndicators, { participantID: participantId, screenshare: false })))),
        React.createElement("div", { className: styles.container },
            React.createElement(VideoMenuTriggerButton, { hidePopover: hidePopover, local: local, participantId: participantId, popoverVisible: popoverVisible, showPopover: showPopover, thumbnailType: thumbnailType, visible: isHovered }))));
};
export default ThumbnailTopIndicators;
