import React from 'react';
import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import Dialog from '../../../base/ui/components/web/Dialog';
import Switch from '../../../base/ui/components/web/Switch';
import AbstractMuteEveryoneDialog, { abstractMapStateToProps } from '../AbstractMuteEveryoneDialog';
/**
 * A React Component with the contents for a dialog that asks for confirmation
 * from the user before muting all remote participants.
 *
 * @augments AbstractMuteEveryoneDialog
 */
class MuteEveryoneDialog extends AbstractMuteEveryoneDialog {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        return (React.createElement(Dialog, { ok: { translationKey: 'dialog.muteParticipantButton' }, onSubmit: this._onSubmit, title: this.props.title },
            React.createElement("div", { className: 'mute-dialog' },
                this.state.content,
                this.props.isModerationSupported && this.props.exclude.length === 0 && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'separator-line' }),
                    React.createElement("div", { className: 'control-row' },
                        React.createElement("label", { htmlFor: 'moderation-switch' }, this.props.t('dialog.moderationAudioLabel')),
                        React.createElement(Switch, { checked: !this.state.audioModerationEnabled, id: 'moderation-switch', onChange: this._onToggleModeration })))))));
    }
}
export default translate(connect(abstractMapStateToProps)(MuteEveryoneDialog));
