/**
 * The types of the buttons.
 */
export var BUTTON_TYPES;
(function (BUTTON_TYPES) {
    BUTTON_TYPES["DESTRUCTIVE"] = "destructive";
    BUTTON_TYPES["PRIMARY"] = "primary";
    BUTTON_TYPES["SECONDARY"] = "secondary";
    BUTTON_TYPES["TERTIARY"] = "tertiary";
})(BUTTON_TYPES || (BUTTON_TYPES = {}));
/**
 * Behaviour types for showing overflow text content.
 */
export var TEXT_OVERFLOW_TYPES;
(function (TEXT_OVERFLOW_TYPES) {
    TEXT_OVERFLOW_TYPES["ELLIPSIS"] = "ellipsis";
    TEXT_OVERFLOW_TYPES["SCROLL_ON_HOVER"] = "scroll-on-hover";
})(TEXT_OVERFLOW_TYPES || (TEXT_OVERFLOW_TYPES = {}));
/**
 * The modes of the buttons.
 */
export const BUTTON_MODES = {
    CONTAINED: 'contained',
    TEXT: 'text'
};
