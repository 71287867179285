import { connect } from 'react-redux';
import { translate } from '../../../../base/i18n/functions';
import { toggleSecurityDialog } from '../../../actions';
import AbstractSecurityDialogButton, { _mapStateToProps as _abstractMapStateToProps } from '../AbstractSecurityDialogButton';
/**
 * Implements an {@link AbstractSecurityDialogButton} to open the security dialog.
 */
class SecurityDialogButton extends AbstractSecurityDialogButton {
    /**
     * Opens / closes the security dialog.
     *
     * @private
     * @returns {void}
     */
    _handleClickSecurityButton() {
        const { dispatch } = this.props;
        dispatch(toggleSecurityDialog());
    }
}
export default translate(connect(_abstractMapStateToProps)(SecurityDialogButton));
