import { IconHangup } from '../../icons/svg';
import AbstractButton from './AbstractButton';
/**
 * An abstract implementation of a button for disconnecting a conference.
 */
export default class AbstractHangupButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.icon = IconHangup;
    }
    /**
     * Handles clicking / pressing the button, and disconnects the conference.
     *
     * @protected
     * @returns {void}
     */
    _handleClick() {
        this._doHangup();
    }
    /**
     * Helper function to perform the actual hangup action.
     *
     * @protected
     * @returns {void}
     */
    _doHangup() {
        // To be implemented by subclass.
    }
}
