import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TogglePinToStageButton from '../../../../features/video-menu/components/web/TogglePinToStageButton';
import Avatar from '../../../base/avatar/components/Avatar';
import { IconPlay } from '../../../base/icons/svg';
import { isWhiteboardParticipant } from '../../../base/participants/functions';
import ContextMenu from '../../../base/ui/components/web/ContextMenu';
import ContextMenuItemGroup from '../../../base/ui/components/web/ContextMenuItemGroup';
import { stopSharedVideo } from '../../../shared-video/actions.any';
import { getParticipantMenuButtonsWithNotifyClick, showOverflowDrawer } from '../../../toolbox/functions.web';
import { NOTIFY_CLICK_MODE } from '../../../toolbox/types';
import { setWhiteboardOpen } from '../../../whiteboard/actions';
import { WHITEBOARD_ID } from '../../../whiteboard/constants';
import { PARTICIPANT_MENU_BUTTONS as BUTTONS } from '../../constants';
const FakeParticipantContextMenu = ({ className, closeDrawer, drawerParticipant, localVideoOwner, offsetTarget, onEnter, onLeave, onSelect, participant, thumbnailMenu }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const _overflowDrawer = useSelector(showOverflowDrawer);
    const buttonsWithNotifyClick = useSelector(getParticipantMenuButtonsWithNotifyClick);
    const notifyClick = useCallback((buttonKey, participantId) => {
        const notifyMode = buttonsWithNotifyClick?.get(buttonKey);
        if (!notifyMode) {
            return;
        }
        APP.API.notifyParticipantMenuButtonClicked(buttonKey, participantId, notifyMode === NOTIFY_CLICK_MODE.PREVENT_AND_NOTIFY);
    }, [buttonsWithNotifyClick]);
    const clickHandler = useCallback(() => onSelect(true), [onSelect]);
    const _onStopSharedVideo = useCallback(() => {
        clickHandler();
        dispatch(stopSharedVideo());
    }, [stopSharedVideo]);
    const _onHideWhiteboard = useCallback(() => {
        clickHandler();
        dispatch(setWhiteboardOpen(false));
    }, [setWhiteboardOpen]);
    const _getActions = useCallback(() => {
        if (isWhiteboardParticipant(participant)) {
            return [{
                    accessibilityLabel: t('toolbar.hideWhiteboard'),
                    icon: IconPlay,
                    onClick: _onHideWhiteboard,
                    text: t('toolbar.hideWhiteboard')
                }];
        }
        if (localVideoOwner) {
            return [{
                    accessibilityLabel: t('toolbar.stopSharedVideo'),
                    icon: IconPlay,
                    onClick: _onStopSharedVideo,
                    text: t('toolbar.stopSharedVideo')
                }];
        }
    }, [localVideoOwner, participant.fakeParticipant]);
    return (React.createElement(ContextMenu, { className: className, entity: participant, hidden: thumbnailMenu ? false : undefined, inDrawer: thumbnailMenu && _overflowDrawer, isDrawerOpen: Boolean(drawerParticipant), offsetTarget: offsetTarget, onClick: onSelect, onDrawerClose: thumbnailMenu ? onSelect : closeDrawer, onMouseEnter: onEnter, onMouseLeave: onLeave },
        !thumbnailMenu && _overflowDrawer && drawerParticipant && React.createElement(ContextMenuItemGroup, { actions: [{
                    accessibilityLabel: drawerParticipant.displayName,
                    customIcon: React.createElement(Avatar, { participantId: drawerParticipant.participantID, size: 20 }),
                    text: drawerParticipant.displayName
                }] }),
        React.createElement(ContextMenuItemGroup, { actions: _getActions() }, isWhiteboardParticipant(participant) && (React.createElement(TogglePinToStageButton, { key: 'pinToStage', 
            // eslint-disable-next-line react/jsx-no-bind
            notifyClick: () => notifyClick(BUTTONS.PIN_TO_STAGE, WHITEBOARD_ID), notifyMode: buttonsWithNotifyClick?.get(BUTTONS.PIN_TO_STAGE), participantID: WHITEBOARD_ID })))));
};
export default FakeParticipantContextMenu;
