import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../base/i18n/functions';
import { getParticipantById } from '../../base/participants/functions';
import { Text } from '../../base/react/components/index';
import { STATUS_TO_I18N_KEY } from '../constants';
import { presenceStatusDisabled } from '../functions';
/**
 * React {@code Component} for displaying the current presence status of a
 * participant.
 *
 * @augments Component
 */
class PresenceLabel extends Component {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const text = this._getPresenceText();
        if (text === null) {
            return null;
        }
        const { style, className } = this.props;
        return (React.createElement(Text // @ts-ignore
        , { className: className, ...style }, text));
    }
    /**
     * Returns the text associated with the current presence status.
     *
     * @returns {string}
     */
    _getPresenceText() {
        const { _presence, t } = this.props;
        if (!_presence) {
            return null;
        }
        const i18nKey = STATUS_TO_I18N_KEY[_presence];
        if (!i18nKey) { // fallback to status value
            return _presence;
        }
        return t(i18nKey);
    }
}
/**
 * The default values for {@code PresenceLabel} component's property types.
 *
 * @static
 */
PresenceLabel.defaultProps = {
    _presence: ''
};
/**
 * Maps (parts of) the Redux state to the associated {@code PresenceLabel}'s
 * props.
 *
 * @param {Object} state - The Redux state.
 * @param {Object} ownProps - The React Component props passed to the associated
 * instance of {@code PresenceLabel}.
 * @private
 * @returns {{
 *     _presence: (string|undefined)
 * }}
 */
function _mapStateToProps(state, ownProps) {
    const participant = getParticipantById(state, ownProps.participantID);
    return {
        _presence: presenceStatusDisabled() ? ''
            : participant?.presence || ownProps.defaultPresence
    };
}
export default translate(connect(_mapStateToProps)(PresenceLabel));
