import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { translate } from '../../../../base/i18n/functions';
import { withPixelLineHeight } from '../../../../base/styles/functions.web';
import Input from '../../../../base/ui/components/web/Input';
import AbstractStreamKeyForm, { _mapStateToProps } from '../AbstractStreamKeyForm';
const styles = (theme) => {
    return {
        helperLink: {
            cursor: 'pointer',
            color: theme.palette.link01,
            transition: 'color .2s ease',
            ...withPixelLineHeight(theme.typography.labelBold),
            marginLeft: 'auto',
            marginTop: theme.spacing(1),
            '&:hover': {
                textDecoration: 'underline',
                color: theme.palette.link01Hover
            },
            '&:active': {
                color: theme.palette.link01Active
            }
        }
    };
};
/**
 * A React Component for entering a key for starting a YouTube live stream.
 *
 * @augments Component
 */
class StreamKeyForm extends AbstractStreamKeyForm {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { t, value } = this.props;
        const classes = withStyles.getClasses(this.props);
        return (React.createElement("div", { className: 'stream-key-form' },
            React.createElement(Input, { autoFocus: true, id: 'streamkey-input', label: t('dialog.streamKey'), name: 'streamId', onChange: this._onInputChange, placeholder: t('liveStreaming.enterStreamKey'), type: 'text', value: value }),
            React.createElement("div", { className: 'form-footer' },
                React.createElement("div", { className: 'help-container' },
                    this.state.showValidationError
                        ? React.createElement("span", { className: 'warning-text' }, t('liveStreaming.invalidStreamKey'))
                        : null,
                    this.props._liveStreaming.helpURL
                        ? React.createElement("a", { className: classes.helperLink, href: this.props._liveStreaming.helpURL, rel: 'noopener noreferrer', target: '_blank' }, t('liveStreaming.streamIdHelp'))
                        : null),
                React.createElement("a", { className: classes.helperLink, href: this.props._liveStreaming.termsURL, rel: 'noopener noreferrer', target: '_blank' }, t('liveStreaming.youtubeTerms')),
                React.createElement("a", { className: classes.helperLink, href: this.props._liveStreaming.dataPrivacyURL, rel: 'noopener noreferrer', target: '_blank' }, t('liveStreaming.googlePrivacyPolicy')))));
    }
}
export default translate(connect(_mapStateToProps)(withStyles(StreamKeyForm, styles)));
