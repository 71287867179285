import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../base/i18n/functions';
import Icon from '../../../base/icons/components/Icon';
import { IconCheck } from '../../../base/icons/svg';
const useStyles = makeStyles()(theme => {
    return {
        itemContainer: {
            display: 'flex',
            color: theme.palette.text02,
            alignItems: 'center',
            fontSize: '14px',
            cursor: 'pointer',
            padding: '5px 0',
            '&:hover': {
                backgroundColor: theme.palette.ui04
            }
        },
        iconWrapper: {
            margin: '4px 10px',
            width: '22px',
            height: '22px'
        },
        activeItemContainer: {
            fontWeight: 700
        }
    };
});
/**
 * Component that renders the language list item.
 *
 * @returns {React$Element<any>}
 */
const LanguageListItem = ({ t, lang, selected, onLanguageSelected }) => {
    const { classes: styles } = useStyles();
    const onLanguageSelectedWrapper = useCallback(() => onLanguageSelected(lang), [lang]);
    return (React.createElement("div", { className: `${styles.itemContainer} ${selected ? styles.activeItemContainer : ''}`, onClick: onLanguageSelectedWrapper },
        React.createElement("span", { className: styles.iconWrapper }, selected
            && React.createElement(Icon, { src: IconCheck })),
        t(lang)));
};
export default translate(LanguageListItem);
