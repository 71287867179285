import React from 'react';
import { translate } from '../../../base/i18n/functions';
import AbstractSuspendedOverlay from './AbstractSuspendedOverlay';
import OverlayFrame from './OverlayFrame';
import ReloadButton from './ReloadButton';
/**
 * Implements a React Component for suspended overlay. Shown when a suspend is
 * detected.
 */
class SuspendedOverlay extends AbstractSuspendedOverlay {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { t } = this.props;
        return (React.createElement(OverlayFrame, null,
            React.createElement("div", { className: 'inlay' },
                React.createElement("span", { className: 'inlay__icon icon-microphone' }),
                React.createElement("span", { className: 'inlay__icon icon-camera' }),
                React.createElement("h3", { className: 'inlay__title' }, t('suspendedoverlay.title')),
                React.createElement(ReloadButton, { textKey: 'suspendedoverlay.rejoinKeyTitle' }))));
    }
}
export default translate(SuspendedOverlay);
