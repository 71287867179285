/**
 * The type of the action which signals document editing has stopped or started.
 *
 * {
 *     type: SET_DOCUMENT_EDITING_STATUS
 * }
 */
export const SET_DOCUMENT_EDITING_STATUS = 'SET_DOCUMENT_EDITING_STATUS';
/**
 * The type of the action which updates the shared document URL.
 *
 * {
 *     type: SET_DOCUMENT_URL
 * }
 */
export const SET_DOCUMENT_URL = 'SET_DOCUMENT_URL';
/**
 * The type of the action which signals to start or stop editing a shared
 * document.
 *
 * {
 *     type: TOGGLE_DOCUMENT_EDITING
 * }
 */
export const TOGGLE_DOCUMENT_EDITING = 'TOGGLE_DOCUMENT_EDITING';
