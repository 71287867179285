import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getParticipantById } from '../../base/participants/functions';
import Dialog from '../../base/ui/components/web/Dialog';
import { participantVerified } from '../actions';
const useStyles = makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            margin: '16px'
        },
        row: {
            alignSelf: 'center',
            display: 'flex'
        },
        item: {
            textAlign: 'center',
            margin: '16px'
        },
        emoji: {
            fontSize: '40px',
            margin: '12px'
        }
    };
});
const ParticipantVerificationDialog = ({ dispatch, participantName, pId, sas }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const _onDismissed = useCallback(() => {
        dispatch(participantVerified(pId, false));
        return true;
    }, [pId]);
    const _onConfirmed = useCallback(() => {
        dispatch(participantVerified(pId, true));
        return true;
    }, [pId]);
    const { emoji } = sas;
    return (React.createElement(Dialog, { cancel: { translationKey: 'dialog.verifyParticipantDismiss' }, ok: { translationKey: 'dialog.verifyParticipantConfirm' }, onCancel: _onDismissed, onSubmit: _onConfirmed, titleKey: 'dialog.verifyParticipantTitle' },
        React.createElement("div", null, t('dialog.verifyParticipantQuestion', { participantName })),
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classes.row }, emoji.slice(0, 4).map((e) => (React.createElement("div", { className: classes.item, key: e.toString() },
                React.createElement("div", { className: classes.emoji }, e[0]),
                React.createElement("div", null, e[1].charAt(0).toUpperCase() + e[1].slice(1)))))),
            React.createElement("div", { className: classes.row }, emoji.slice(4, 7).map((e) => (React.createElement("div", { className: classes.item, key: e.toString() },
                React.createElement("div", { className: classes.emoji },
                    e[0],
                    " "),
                React.createElement("div", null, e[1].charAt(0).toUpperCase() + e[1].slice(1)))))))));
};
/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {IReduxState} state - The Redux state.
 * @param {IProps} ownProps - The own props of the component.
 * @returns {IProps}
 */
export function _mapStateToProps(state, ownProps) {
    const participant = getParticipantById(state, ownProps.pId);
    return {
        sas: ownProps.sas,
        pId: ownProps.pId,
        participantName: participant?.name
    };
}
export default connect(_mapStateToProps)(ParticipantVerificationDialog);
