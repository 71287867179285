import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { updateDialInNumbers } from '../../../../invite/actions.web';
import { getConferenceId, getDefaultDialInNumber } from '../../../../invite/functions';
import { dialOut as dialOutAction, joinConferenceWithoutAudio as joinConferenceWithoutAudioAction, openDialInPage as openDialInPageAction } from '../../../actions.web';
import { getDialOutStatus, getFullDialOutNumber } from '../../../functions';
import CallingDialog from './CallingDialog';
import DialInDialog from './DialInDialog';
import DialOutDialog from './DialOutDialog';
/**
 * This is the dialog shown when a user wants to join with phone audio.
 */
class JoinByPhoneDialog extends PureComponent {
    /**
     * Initializes a new {@code JoinByPhoneDialog} instance.
     *
     * @param {IProps} props - The props of the component.
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this.state = {
            isCalling: false,
            showDialOut: true,
            showDialIn: false
        };
        this._dialOut = this._dialOut.bind(this);
        this._showDialInDialog = this._showDialInDialog.bind(this);
        this._showDialOutDialog = this._showDialOutDialog.bind(this);
    }
    /**
     * Meeting calls the user & shows the 'CallingDialog'.
     *
     * @returns {void}
     */
    _dialOut() {
        const { dialOut, joinConferenceWithoutAudio } = this.props;
        this.setState({
            isCalling: true,
            showDialOut: false,
            showDialIn: false
        });
        dialOut(joinConferenceWithoutAudio, this._showDialOutDialog);
    }
    /**
     * Shows the 'DialInDialog'.
     *
     * @returns {void}
     */
    _showDialInDialog() {
        this.setState({
            isCalling: false,
            showDialOut: false,
            showDialIn: true
        });
    }
    /**
     * Shows the 'DialOutDialog'.
     *
     * @returns {void}
     */
    _showDialOutDialog() {
        this.setState({
            isCalling: false,
            showDialOut: true,
            showDialIn: false
        });
    }
    /**
     * Implements React's {@link Component#componentDidMount()}.
     *
     * @inheritdoc
     */
    componentDidMount() {
        this.props.fetchConferenceDetails();
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { dialOutStatus, dialInNumber, dialOutNumber, joinConferenceWithoutAudio, passCode, onClose, openDialInPage } = this.props;
        const { _dialOut, _showDialInDialog, _showDialOutDialog } = this;
        const { isCalling, showDialOut, showDialIn } = this.state;
        const className = isCalling
            ? 'prejoin-dialog prejoin-dialog--small'
            : 'prejoin-dialog';
        return (React.createElement("div", { className: 'prejoin-dialog-container' },
            React.createElement("div", { className: className },
                showDialOut && (React.createElement(DialOutDialog, { onClose: onClose, onSubmit: _dialOut, onTextButtonClick: _showDialInDialog })),
                showDialIn && (React.createElement(DialInDialog, { number: dialInNumber, onBack: _showDialOutDialog, onPrimaryButtonClick: joinConferenceWithoutAudio, onSmallTextClick: openDialInPage, onTextButtonClick: onClose, passCode: passCode })),
                isCalling && (React.createElement(CallingDialog, { number: dialOutNumber, onClose: onClose, status: dialOutStatus })))));
    }
}
/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @param {Object} _ownProps - Component's own props.
 * @returns {Object}
 */
function mapStateToProps(state, _ownProps) {
    return {
        dialInNumber: getDefaultDialInNumber(state),
        dialOutNumber: getFullDialOutNumber(state),
        dialOutStatus: getDialOutStatus(state),
        passCode: getConferenceId(state)
    };
}
const mapDispatchToProps = {
    dialOut: dialOutAction,
    fetchConferenceDetails: updateDialInNumbers,
    joinConferenceWithoutAudio: joinConferenceWithoutAudioAction,
    openDialInPage: openDialInPageAction
};
export default connect(mapStateToProps, mapDispatchToProps)(JoinByPhoneDialog);
