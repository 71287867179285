import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { isMobileBrowser } from '../../../../../base/environment/utils';
import { isLocalParticipantModerator } from '../../../../../base/participants/functions';
import { equals } from '../../../../../base/redux/functions';
import useContextMenu from '../../../../../base/ui/hooks/useContextMenu.web';
import { getBreakoutRooms, getBreakoutRoomsConfig, getCurrentRoomId, isAutoAssignParticipantsVisible, isInBreakoutRoom } from '../../../../../breakout-rooms/functions';
import { AutoAssignButton } from './AutoAssignButton';
import { CollapsibleRoom } from './CollapsibleRoom';
import JoinActionButton from './JoinQuickActionButton';
import { LeaveButton } from './LeaveButton';
import RoomActionEllipsis from './RoomActionEllipsis';
import { RoomContextMenu } from './RoomContextMenu';
import { RoomParticipantContextMenu } from './RoomParticipantContextMenu';
const useStyles = makeStyles()(theme => {
    return {
        topMargin: {
            marginTop: theme.spacing(3)
        }
    };
});
export const RoomList = ({ searchString }) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const currentRoomId = useSelector(getCurrentRoomId);
    const rooms = Object.values(useSelector(getBreakoutRooms, equals))
        .filter((room) => room.id !== currentRoomId)
        .sort((p1, p2) => (p1?.name || '').localeCompare(p2?.name || ''));
    const inBreakoutRoom = useSelector(isInBreakoutRoom);
    const isLocalModerator = useSelector(isLocalParticipantModerator);
    const showAutoAssign = useSelector(isAutoAssignParticipantsVisible);
    const { hideJoinRoomButton } = useSelector(getBreakoutRoomsConfig);
    const [lowerMenu, raiseMenu, toggleMenu, menuEnter, menuLeave, raiseContext] = useContextMenu();
    const [lowerParticipantMenu, raiseParticipantMenu, toggleParticipantMenu, participantMenuEnter, participantMenuLeave, raiseParticipantContext] = useContextMenu();
    const onRaiseMenu = useCallback(room => (target) => raiseMenu(room, target), [raiseMenu]);
    // close the menu when the room vanishes
    useEffect(() => {
        if (raiseContext.entity && !rooms.some(r => r.id === raiseContext.entity?.id)) {
            lowerMenu();
        }
    }, [raiseContext, rooms, lowerMenu]);
    return (React.createElement(React.Fragment, null,
        inBreakoutRoom && React.createElement(LeaveButton, { className: classes.topMargin }),
        showAutoAssign && React.createElement(AutoAssignButton, { className: classes.topMargin }),
        React.createElement("div", { "aria-label": t('breakoutRooms.breakoutList', 'breakout list'), className: classes.topMargin, id: 'breakout-rooms-list', role: 'list' }, rooms.map(room => (React.createElement(React.Fragment, { key: room.id },
            React.createElement(CollapsibleRoom, { isHighlighted: true, onRaiseMenu: onRaiseMenu(room), participantContextEntity: raiseParticipantContext.entity, raiseParticipantContextMenu: raiseParticipantMenu, room: room, searchString: searchString, toggleParticipantMenu: toggleParticipantMenu }, !isMobileBrowser() && React.createElement(React.Fragment, null,
                !hideJoinRoomButton && React.createElement(JoinActionButton, { room: room }),
                isLocalModerator && !room.isMainRoom
                    && React.createElement(RoomActionEllipsis, { onClick: toggleMenu(room) }))))))),
        React.createElement(RoomContextMenu, { onEnter: menuEnter, onLeave: menuLeave, onSelect: lowerMenu, ...raiseContext }),
        React.createElement(RoomParticipantContextMenu, { onEnter: participantMenuEnter, onLeave: participantMenuLeave, onSelect: lowerParticipantMenu, ...raiseParticipantContext })));
};
