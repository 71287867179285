import React from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => {
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            overflow: 'hidden',
            maxHeight: '150px',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                flexGrow: 1
            }
        }
    };
});
const GifMessage = ({ url }) => {
    const { classes: styles } = useStyles();
    return (React.createElement("div", { className: styles.container },
        React.createElement("img", { alt: url, src: url })));
};
export default GifMessage;
