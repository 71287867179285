export * from './functions';
import { getDisplayName, getSpaceUsage } from './functions';
import logger from './logger';
/**
 * Fetches information about the user's dropbox account.
 *
 * @param {string} token - The dropbox access token.
 * @param {string} appKey - The Jitsi Recorder dropbox app key.
 * @returns {Promise<DropboxUserData|undefined>}
 */
export function getDropboxData(token, appKey) {
    return Promise.all([getDisplayName(token, appKey), getSpaceUsage(token, appKey)]).then(([userName, space]) => {
        const { allocated, used } = space;
        return {
            userName,
            spaceLeft: Math.floor((allocated - used) / 1048576) // 1MiB=1048576B
        };
    }, error => {
        logger.error(error);
        return undefined;
    });
}
