import React from 'react';
import { useSelector } from 'react-redux';
import SvgXmlIcon from './SvgXmlIcon';
/**
 * Icon wrapper that checks for branding before returning the SVG component.
 *
 * @returns {JSX.Element}
 */
const withBranding = ({ DefaultIcon, iconName }) => (props) => {
    const src = useSelector((state) => state['features/dynamic-branding']?.brandedIcons?.[iconName]);
    if (src) {
        return (React.createElement(SvgXmlIcon, { src: src, ...props }));
    }
    return React.createElement(DefaultIcon, { ...props });
};
export default withBranding;
