import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createToolbarEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import { leaveConference } from '../../../base/conference/actions';
import { BUTTON_TYPES } from '../../../base/ui/constants.web';
import { HangupContextMenuItem } from './HangupContextMenuItem';
/**
 * Button to leave the conference.
 *
 * @param {Object} props - Component's props.
 * @returns {JSX.Element} - The leave conference button.
 */
export const LeaveConferenceButton = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const onLeaveConference = useCallback(() => {
        sendAnalytics(createToolbarEvent('hangup'));
        dispatch(leaveConference());
    }, [dispatch]);
    return (React.createElement(HangupContextMenuItem, { accessibilityLabel: t('toolbar.accessibilityLabel.leaveConference'), buttonKey: props.buttonKey, buttonType: BUTTON_TYPES.SECONDARY, label: t('toolbar.leaveConference'), notifyMode: props.notifyMode, onClick: onLeaveConference }));
};
