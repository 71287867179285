import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../base/ui/components/web/Input';
import { LOCKED_LOCALLY } from '../../../../room-lock/constants';
/**
 * React {@code Component} for displaying and editing the conference password.
 *
 * @returns {ReactElement}
 */
export default function PasswordForm({ editEnabled, locked, onSubmit, password, passwordNumberOfDigits, visible }) {
    const { t } = useTranslation();
    const [enteredPassword, setEnteredPassword] = useState('');
    const onKeyPress = useCallback(event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            onSubmit(enteredPassword);
        }
    }, [onSubmit, enteredPassword]);
    if (!editEnabled && enteredPassword && enteredPassword !== '') {
        setEnteredPassword('');
    }
    const placeHolderText = passwordNumberOfDigits ? t('passwordDigitsOnly', { number: passwordNumberOfDigits }) : t('dialog.password');
    return (React.createElement("div", { className: 'info-password' },
        locked && React.createElement(React.Fragment, null,
            React.createElement("span", { className: 'info-label' }, t('info.password')),
            React.createElement("span", { className: 'spacer' }, "\u00A0"),
            React.createElement("span", { className: 'info-password-field info-value' }, locked === LOCKED_LOCALLY ? (React.createElement("div", { className: 'info-password-local' }, visible ? password : '******')) : (React.createElement("div", { className: 'info-password-remote' }, t('passwordSetRemotely'))))),
        editEnabled && React.createElement("div", { className: 'info-password-form' },
            React.createElement(Input, { accessibilityLabel: t('info.addPassword'), autoFocus: true, id: 'info-password-input', maxLength: passwordNumberOfDigits, mode: passwordNumberOfDigits ? 'numeric' : undefined, onChange: setEnteredPassword, onKeyPress: onKeyPress, placeholder: placeHolderText, type: 'password', value: enteredPassword }))));
}
