import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { createProfilePanelButtonEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import { login, logout } from '../../../authentication/actions.web';
import Avatar from '../../../base/avatar/components/Avatar';
import AbstractDialogTab from '../../../base/dialog/components/web/AbstractDialogTab';
import { translate } from '../../../base/i18n/functions';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import Button from '../../../base/ui/components/web/Button';
import Input from '../../../base/ui/components/web/Input';
const styles = (theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '0 2px'
        },
        avatarContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginBottom: theme.spacing(4)
        },
        bottomMargin: {
            marginBottom: theme.spacing(4)
        },
        label: {
            color: `${theme.palette.text01} !important`,
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            marginBottom: theme.spacing(2)
        },
        name: {
            marginBottom: theme.spacing(1)
        }
    };
};
/**
 * React {@code Component} for modifying the local user's profile.
 *
 * @augments Component
 */
class ProfileTab extends AbstractDialogTab {
    /**
     * Initializes a new {@code ConnectedSettingsDialog} instance.
     *
     * @param {IProps} props - The React {@code Component} props to initialize
     * the new {@code ConnectedSettingsDialog} instance with.
     */
    constructor(props) {
        super(props);
        // Bind event handlers so they are only bound once for every instance.
        this._onAuthToggle = this._onAuthToggle.bind(this);
        this._onDisplayNameChange = this._onDisplayNameChange.bind(this);
        this._onEmailChange = this._onEmailChange.bind(this);
    }
    /**
     * Changes display name of the user.
     *
     * @param {string} value - The key event to handle.
     *
     * @returns {void}
     */
    _onDisplayNameChange(value) {
        super._onChange({ displayName: value });
    }
    /**
     * Changes email of the user.
     *
     * @param {string} value - The key event to handle.
     *
     * @returns {void}
     */
    _onEmailChange(value) {
        super._onChange({ email: value });
    }
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { authEnabled, displayName, email, hideEmailInSettings, id, readOnlyName, t } = this.props;
        const classes = withStyles.getClasses(this.props);
        return (React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classes.avatarContainer },
                React.createElement(Avatar, { participantId: id, size: 60 })),
            React.createElement(Input, { className: classes.bottomMargin, disabled: readOnlyName, id: 'setDisplayName', label: t('profile.setDisplayNameLabel'), name: 'name', onChange: this._onDisplayNameChange, placeholder: t('settings.name'), type: 'text', value: displayName }),
            !hideEmailInSettings && React.createElement("div", { className: 'profile-edit-field' },
                React.createElement(Input, { className: classes.bottomMargin, id: 'setEmail', label: t('profile.setEmailLabel'), name: 'email', onChange: this._onEmailChange, placeholder: t('profile.setEmailInput'), type: 'text', value: email })),
            authEnabled && this._renderAuth()));
    }
    /**
     * Shows the dialog for logging in or out of a server and closes this
     * dialog.
     *
     * @private
     * @returns {void}
     */
    _onAuthToggle() {
        if (this.props.authLogin) {
            sendAnalytics(createProfilePanelButtonEvent('logout.button'));
            this.props.dispatch(logout());
        }
        else {
            sendAnalytics(createProfilePanelButtonEvent('login.button'));
            this.props.dispatch(login());
        }
    }
    /**
     * Returns a React Element for interacting with server-side authentication.
     *
     * @private
     * @returns {ReactElement}
     */
    _renderAuth() {
        const { authLogin, t } = this.props;
        const classes = withStyles.getClasses(this.props);
        return (React.createElement("div", null,
            React.createElement("h2", { className: classes.label }, t('toolbar.authenticate')),
            authLogin
                && React.createElement("div", { className: classes.name }, t('settings.loggedIn', { name: authLogin })),
            React.createElement(Button, { accessibilityLabel: authLogin ? t('toolbar.logout') : t('toolbar.login'), id: 'login_button', label: authLogin ? t('toolbar.logout') : t('toolbar.login'), onClick: this._onAuthToggle })));
    }
}
ProfileTab.defaultProps = {
    displayName: '',
    email: ''
};
export default withStyles(translate(connect()(ProfileTab)), styles);
