import { connect } from 'react-redux';
import { createToolbarEvent } from '../../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../../analytics/functions';
import { isIosMobileBrowser } from '../../../base/environment/utils';
import { translate } from '../../../base/i18n/functions';
import { IconEnterFullscreen, IconExitFullscreen } from '../../../base/icons/svg';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import { closeOverflowMenuIfOpen, setFullScreen } from '../../actions.web';
/**
 * Implementation of a button for toggling fullscreen state.
 */
class FullscreenButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.enterFullScreen';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.exitFullScreen';
        this.label = 'toolbar.enterFullScreen';
        this.toggledLabel = 'toolbar.exitFullScreen';
        this.tooltip = 'toolbar.enterFullScreen';
        this.toggledTooltip = 'toolbar.exitFullScreen';
        this.toggledIcon = IconExitFullscreen;
        this.icon = IconEnterFullscreen;
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._fullScreen;
    }
    /**
    * Handles clicking the button, and toggles fullscreen.
    *
    * @private
    * @returns {void}
    */
    _handleClick() {
        const { dispatch, _fullScreen } = this.props;
        sendAnalytics(createToolbarEvent('toggle.fullscreen', {
            enable: !_fullScreen
        }));
        dispatch(closeOverflowMenuIfOpen());
        dispatch(setFullScreen(!_fullScreen));
    }
}
/**
 * Function that maps parts of Redux state tree into component props.
 *
 * @param {Object} state - Redux state.
 * @returns {Object}
 */
const mapStateToProps = (state) => {
    return {
        _fullScreen: state['features/toolbox'].fullScreen,
        visible: !isIosMobileBrowser()
    };
};
export default translate(connect(mapStateToProps)(FullscreenButton));
