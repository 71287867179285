import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../base/ui/components/web/Dialog';
/**
 * Implements the dialog that warns the user that the login will leave the conference.
 *
 * @param {Object} props - The props of the component.
 * @returns {React$Element}
 */
const LoginQuestionDialog = ({ handler }) => {
    const { t } = useTranslation();
    return (React.createElement(Dialog, { ok: { translationKey: 'dialog.Yes' }, onSubmit: handler, titleKey: t('dialog.login') },
        React.createElement("div", null, t('dialog.loginQuestion'))));
};
export default LoginQuestionDialog;
