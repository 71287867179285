import React from 'react';
import { useSelector } from 'react-redux';
import { shouldShowResults } from '../../functions';
import PollAnswer from './PollAnswer';
import PollResults from './PollResults';
const PollItem = React.forwardRef(({ pollId, setCreateMode }, ref) => {
    const showResults = useSelector(shouldShowResults(pollId));
    return (React.createElement("div", { ref: ref }, showResults
        ? React.createElement(PollResults, { key: pollId, pollId: pollId })
        : React.createElement(PollAnswer, { pollId: pollId, setCreateMode: setCreateMode })));
});
export default PollItem;
