/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { translate } from '../../../../base/i18n/functions';
import Icon from '../../../../base/icons/components/Icon';
import { IconCheck, IconCopy } from '../../../../base/icons/svg';
import { copyText } from '../../../../base/util/copyText.web';
/**
 * Section of the {@code AddPeopleDialog} that renders the
 * live streaming url, allowing a copy action.
 *
 * @returns {React$Element<any>}
 */
function LiveStreamSection({ liveStreamViewURL, t }) {
    const [isClicked, setIsClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    /**
     * Click handler for the element.
     *
     * @returns {void}
     */
    async function onClick() {
        setIsHovered(false);
        const isCopied = await copyText(liveStreamViewURL);
        if (isCopied) {
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 2500);
        }
    }
    /**
     * Hover handler for the element.
     *
     * @returns {void}
     */
    function onHoverIn() {
        if (!isClicked) {
            setIsHovered(true);
        }
    }
    /**
     * Hover handler for the element.
     *
     * @returns {void}
     */
    function onHoverOut() {
        setIsHovered(false);
    }
    /**
     * Renders the content of the link based on the state.
     *
     * @returns {React$Element<any>}
     */
    function renderLinkContent() {
        if (isClicked) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'invite-more-dialog stream-text selected' }, t('addPeople.linkCopied')),
                React.createElement(Icon, { src: IconCheck })));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'invite-more-dialog stream-text' }, isHovered ? t('addPeople.copyStream') : liveStreamViewURL),
            React.createElement(Icon, { src: IconCopy })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null, t('addPeople.shareStream')),
        React.createElement("div", { className: `invite-more-dialog stream${isClicked ? ' clicked' : ''}`, onClick: onClick, onMouseOut: onHoverOut, onMouseOver: onHoverIn }, renderLinkContent()),
        React.createElement("div", { className: 'invite-more-dialog separator' })));
}
export default translate(LiveStreamSection);
