import React from 'react';
import { useTranslation } from 'react-i18next';
import InviteContactsForm from './InviteContactsForm';
/**
 * Component that represents the invitation section of the {@code AddPeopleDialog}.
 *
 * @returns {ReactElement$<any>}
 */
function InviteContactsSection() {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null,
        React.createElement("span", null, t('addPeople.addContacts')),
        React.createElement(InviteContactsForm, null),
        React.createElement("div", { className: 'invite-more-dialog separator' })));
}
export default InviteContactsSection;
