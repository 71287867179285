// eslint-disable-next-line lines-around-comment
import React from 'react';
import Avatar from '../../../base/avatar/components/Avatar';
import StatelessAvatar from '../../../base/avatar/components/web/StatelessAvatar';
import { getInitials } from '../../../base/avatar/functions';
import { IconUser } from '../../../base/icons/svg';
import BaseTheme from '../../../base/ui/components/BaseTheme.web';
import TimeElapsed from './TimeElapsed';
import Timeline from './Timeline';
const SpeakerStatsItem = (props) => {
    const rowDisplayClass = `row item ${props.hasLeft ? 'has-left' : ''}`;
    const nameTimeClass = `name-time${props.showFaceExpressions ? ' expressions-on' : ''}`;
    const timeClass = `time ${props.isDominantSpeaker ? 'dominant' : ''}`;
    return (React.createElement("div", { key: props.participantId },
        React.createElement("div", { className: rowDisplayClass },
            React.createElement("div", { className: 'avatar' }, props.hasLeft ? (React.createElement(StatelessAvatar, { className: 'userAvatar', color: BaseTheme.palette.ui04, iconUser: IconUser, initials: getInitials(props.displayName), size: 32 })) : (React.createElement(Avatar, { className: 'userAvatar', participantId: props.participantId, size: 32 }))),
            React.createElement("div", { className: nameTimeClass },
                React.createElement("div", { "aria-label": props.t('speakerStats.speakerStats'), className: 'display-name' }, props.displayName),
                React.createElement("div", { "aria-label": props.t('speakerStats.speakerTime'), className: timeClass },
                    React.createElement(TimeElapsed, { time: props.dominantSpeakerTime }))),
            props.showFaceExpressions
                && React.createElement(Timeline, { faceLandmarks: props.faceLandmarks })),
        React.createElement("div", { className: 'separator' })));
};
export default SpeakerStatsItem;
