import { connect } from 'react-redux';
import { translate } from '../../../base/i18n/functions';
import { IconVolumeOff, IconVolumeUp } from '../../../base/icons/svg';
import JitsiMeetJS from '../../../base/lib-jitsi-meet';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
import { setOverflowMenuVisible } from '../../../toolbox/actions.web';
import { startAudioScreenShareFlow } from '../../actions.web';
import { isAudioOnlySharing, isScreenAudioSupported } from '../../functions';
/**
 * Component that renders a toolbar button for toggling audio only screen share.
 */
class ShareAudioButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.shareaudio';
        this.icon = IconVolumeUp;
        this.label = 'toolbar.shareaudio';
        this.tooltip = 'toolbar.shareaudio';
        this.toggledIcon = IconVolumeOff;
        this.toggledLabel = 'toolbar.stopAudioSharing';
    }
    /**
     * Handles clicking / pressing the button, and opens a new dialog.
     *
     * @private
     * @returns {void}
     */
    _handleClick() {
        const { dispatch } = this.props;
        dispatch(startAudioScreenShareFlow());
        dispatch(setOverflowMenuVisible(false));
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._isAudioOnlySharing;
    }
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {IProps}
 */
function _mapStateToProps(state) {
    return {
        _isAudioOnlySharing: Boolean(isAudioOnlySharing(state)),
        visible: JitsiMeetJS.isDesktopSharingEnabled() && isScreenAudioSupported()
    };
}
export default translate(connect(_mapStateToProps)(ShareAudioButton));
