import React from 'react';
import { connect } from 'react-redux';
import { translate, translateToHTML } from '../../../base/i18n/functions';
import AbstractUserMediaPermissionsOverlay, { abstractMapStateToProps } from './AbstractUserMediaPermissionsOverlay';
import OverlayFrame from './OverlayFrame';
/**
 * Implements a React Component for overlay with guidance how to proceed with
 * gUM prompt.
 */
class UserMediaPermissionsOverlay extends AbstractUserMediaPermissionsOverlay {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { _premeetingBackground, browser, t } = this.props;
        const style = _premeetingBackground ? {
            background: _premeetingBackground,
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        } : {};
        return (React.createElement(OverlayFrame, { style: style },
            React.createElement("div", { className: 'inlay' },
                React.createElement("span", { className: 'inlay__icon icon-microphone' }),
                React.createElement("span", { className: 'inlay__icon icon-camera' }),
                React.createElement("h3", { "aria-label": t('startupoverlay.genericTitle'), className: 'inlay__title', role: 'alert' }, t('startupoverlay.genericTitle')),
                React.createElement("span", { className: 'inlay__text', role: 'alert' }, translateToHTML(t, `userMedia.${browser}GrantPermissions`))),
            React.createElement("div", { className: 'policy overlay__policy' },
                React.createElement("p", { className: 'policy__text', role: 'alert' }, translateToHTML(t, 'startupoverlay.policyText')),
                this._renderPolicyLogo())));
    }
    /**
     * Renders the policy logo.
     *
     * @private
     * @returns {ReactElement|null}
     */
    _renderPolicyLogo() {
        const policyLogoSrc = interfaceConfig.POLICY_LOGO;
        if (policyLogoSrc) {
            return (React.createElement("div", { className: 'policy__logo' },
                React.createElement("img", { alt: this.props.t('welcomepage.logo.policyLogo'), src: policyLogoSrc })));
        }
        return null;
    }
}
/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @param {Object} ownProps - The props passed to the component.
 * @returns {Object}
 */
function mapStateToProps(state) {
    const { premeetingBackground } = state['features/dynamic-branding'];
    return {
        ...abstractMapStateToProps(state),
        _premeetingBackground: premeetingBackground
    };
}
export default translate(connect(mapStateToProps)(UserMediaPermissionsOverlay));
