import { connect } from 'react-redux';
import { createToolbarEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { translate } from '../../base/i18n/functions';
import { IconDownload } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
import { openURLInBrowser } from '../../base/util/openURLInBrowser';
/**
 * Implements an {@link AbstractButton} to open the applications page in a new window.
 */
class DownloadButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.download';
        this.icon = IconDownload;
        this.label = 'toolbar.download';
        this.tooltip = 'toolbar.download';
    }
    /**
     * Handles clicking / pressing the button, and opens a new window with the user documentation.
     *
     * @private
     * @returns {void}
     */
    _handleClick() {
        const { _downloadAppsUrl } = this.props;
        sendAnalytics(createToolbarEvent('download.pressed'));
        openURLInBrowser(_downloadAppsUrl);
    }
}
/**
 * Maps part of the redux state to the component's props.
 *
 * @param {Object} state - The redux store/state.
 * @returns {Object}
 */
function _mapStateToProps(state) {
    const { downloadAppsUrl } = state['features/base/config'].deploymentUrls || {};
    const visible = typeof downloadAppsUrl === 'string';
    return {
        _downloadAppsUrl: downloadAppsUrl ?? '',
        visible
    };
}
export default translate(connect(_mapStateToProps)(DownloadButton));
