import { connect } from 'react-redux';
import { createToolbarEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { translate } from '../../base/i18n/functions';
import { IconFeedback } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
import { openFeedbackDialog } from '../actions';
import { shouldSendJaaSFeedbackMetadata } from '../functions.web';
/**
 * Implementation of a button for opening feedback dialog.
 */
class FeedbackButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.feedback';
        this.icon = IconFeedback;
        this.label = 'toolbar.feedback';
        this.tooltip = 'toolbar.feedback';
    }
    /**
     * Handles clicking / pressing the button, and opens the appropriate dialog.
     *
     * @protected
     * @returns {void}
     */
    _handleClick() {
        const { _conference, dispatch } = this.props;
        sendAnalytics(createToolbarEvent('feedback'));
        dispatch(openFeedbackDialog(_conference));
    }
}
const mapStateToProps = (state) => {
    const { conference } = state['features/base/conference'];
    return {
        _conference: conference,
        visible: shouldSendJaaSFeedbackMetadata(state)
    };
};
export default translate(connect(mapStateToProps)(FeedbackButton));
