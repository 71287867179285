import React from 'react';
import LocalVideoMenuTriggerButton from '../../../video-menu/components/web/LocalVideoMenuTriggerButton';
import RemoteVideoMenuTriggerButton from '../../../video-menu/components/web/RemoteVideoMenuTriggerButton';
// eslint-disable-next-line no-confusing-arrow
const VideoMenuTriggerButton = ({ hidePopover, local, participantId = '', popoverVisible, showPopover, thumbnailType, visible }) => local
    ? (React.createElement("span", { id: 'localvideomenu' },
        React.createElement(LocalVideoMenuTriggerButton, { buttonVisible: visible, hidePopover: hidePopover, popoverVisible: popoverVisible, showPopover: showPopover, thumbnailType: thumbnailType })))
    : (React.createElement("span", { id: 'remotevideomenu' },
        React.createElement(RemoteVideoMenuTriggerButton, { buttonVisible: visible, hidePopover: hidePopover, participantID: participantId, popoverVisible: popoverVisible, showPopover: showPopover, thumbnailType: thumbnailType })));
export default VideoMenuTriggerButton;
