import React, { Component } from 'react';
import { connect } from 'react-redux';
import { removeReaction } from '../../actions.any';
import { REACTIONS } from '../../constants';
/**
 * Used to display animated reactions.
 *
 * @returns {ReactElement}
 */
class ReactionEmoji extends Component {
    /**
     * Initializes a new {@code ReactionEmoji} instance.
     *
     * @param {IProps} props - The read-only React {@code Component} props with
     * which the new instance is to be initialized.
     */
    constructor(props) {
        super(props);
        this.state = {
            index: props.index % 21
        };
    }
    /**
     * Implements React Component's componentDidMount.
     *
     * @inheritdoc
     */
    componentDidMount() {
        setTimeout(() => this.props.reactionRemove(this.props.uid), 5000);
    }
    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { reaction, uid } = this.props;
        const { index } = this.state;
        return (React.createElement("div", { className: `reaction-emoji reaction-${index}`, id: uid }, REACTIONS[reaction].emoji));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        reactionRemove: (uid) => dispatch(removeReaction(uid))
    };
};
export default connect(undefined, mapDispatchToProps)(ReactionEmoji);
