import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ContextMenuItem from './ContextMenuItem';
const useStyles = makeStyles()(theme => {
    return {
        contextMenuItemGroup: {
            '&:not(:empty)': {
                padding: `${theme.spacing(2)} 0`
            },
            '& + &:not(:empty)': {
                borderTop: `1px solid ${theme.palette.ui03}`
            },
            '&:first-of-type': {
                paddingTop: 0
            },
            '&:last-of-type': {
                paddingBottom: 0
            }
        }
    };
});
const ContextMenuItemGroup = ({ actions, children }) => {
    const { classes: styles } = useStyles();
    return (React.createElement("div", { className: styles.contextMenuItemGroup },
        children,
        actions?.map(actionProps => (React.createElement(ContextMenuItem, { key: actionProps.text, ...actionProps })))));
};
export default ContextMenuItemGroup;
