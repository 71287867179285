import React, { Component } from 'react';
import { translate } from '../../base/i18n/functions';
/**
 * A React Component showing a button to sign in with Google.
 *
 * @augments Component
 */
class GoogleSignInButton extends Component {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { t } = this.props;
        return (React.createElement("div", { className: 'google-sign-in', onClick: this.props.onClick },
            React.createElement("img", { alt: t('welcomepage.logo.googleLogo'), className: 'google-logo', src: 'images/googleLogo.svg' }),
            React.createElement("div", { className: 'google-cta' }, t(this.props.signedIn
                ? 'liveStreaming.signOut'
                : 'liveStreaming.signIn'))));
    }
}
export default translate(GoogleSignInButton);
