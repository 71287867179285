import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../../base/i18n/functions';
import Icon from '../../../../base/icons/components/Icon';
import { IconCloseLarge } from '../../../../base/icons/svg';
import Button from '../../../../base/ui/components/web/Button';
import Label from '../Label';
import CountryPicker from '../country-picker/CountryPicker';
const useStyles = makeStyles()(theme => {
    return {
        dialOutDialog: {
            padding: theme.spacing(3)
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(4)
        },
        picker: {
            margin: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`
        }
    };
});
/**
 * This component displays the dialog from which the user can enter the
 * phone number in order to be called by the meeting.
 *
 * @param {IProps} props - The props of the component.
 * @returns {React$Element}
 */
function DialOutDialog(props) {
    const { onClose, onTextButtonClick, onSubmit, t } = props;
    const { classes } = useStyles();
    return (React.createElement("div", { className: classes.dialOutDialog },
        React.createElement("div", { className: classes.header },
            React.createElement("div", { className: 'prejoin-dialog-title' }, t('prejoin.startWithPhone')),
            React.createElement(Icon, { className: 'prejoin-dialog-icon', onClick: onClose, role: 'button', size: 24, src: IconCloseLarge })),
        React.createElement(Label, null, t('prejoin.callMeAtNumber')),
        React.createElement("div", { className: classes.picker },
            React.createElement(CountryPicker, { onSubmit: onSubmit })),
        React.createElement(Button, { className: 'prejoin-dialog-btn', fullWidth: true, labelKey: 'prejoin.callMe', onClick: onSubmit, type: 'primary' }),
        React.createElement("div", { className: 'prejoin-dialog-delimiter-container' },
            React.createElement("div", { className: 'prejoin-dialog-delimiter' }),
            React.createElement("div", { className: 'prejoin-dialog-delimiter-txt-container' },
                React.createElement("span", { className: 'prejoin-dialog-delimiter-txt' }, t('prejoin.or')))),
        React.createElement("div", { className: 'prejoin-dialog-dialin-container' },
            React.createElement(Button, { className: 'prejoin-dialog-btn', fullWidth: true, labelKey: 'prejoin.iWantToDialIn', onClick: onTextButtonClick, type: 'tertiary' }))));
}
export default translate(DialOutDialog);
