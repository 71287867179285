import React from 'react';
import { makeStyles } from 'tss-react/mui';
import LanguageListItem from './LanguageListItem';
const useStyles = makeStyles()(() => {
    return {
        itemsContainer: {
            display: 'flex',
            flexFlow: 'column'
        }
    };
});
/**
 * Component that renders the security options dialog.
 *
 * @returns {React$Element<any>}
 */
const LanguageList = ({ items, onLanguageSelected }) => {
    const { classes: styles } = useStyles();
    const listItems = items.map(item => (React.createElement(LanguageListItem, { key: item.id, lang: item.lang, onLanguageSelected: onLanguageSelected, selected: item.selected })));
    return (React.createElement("div", { className: styles.itemsContainer }, listItems));
};
export default LanguageList;
