import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { countries } from '../../../utils';
import CountryRow from './CountryRow';
const useStyles = makeStyles()(theme => {
    return {
        container: {
            height: '190px',
            width: '343px',
            overflowY: 'auto',
            backgroundColor: theme.palette.ui01
        }
    };
});
/**
 * This component displays the dropdown for the country picker.
 *
 * @returns {ReactElement}
 */
function CountryDropdown({ onEntryClick }) {
    const { classes } = useStyles();
    return (React.createElement("div", { className: classes.container }, countries.map(country => (React.createElement(CountryRow, { country: country, key: `${country.code}`, onEntryClick: onEntryClick })))));
}
export default CountryDropdown;
