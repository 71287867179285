import { ExcalidrawApp } from '@jitsi/excalidraw';
import i18next from 'i18next';
import React, { useCallback, useRef } from 'react';
import { WHITEBOARD_UI_OPTIONS } from '../../constants';
/**
 * Whiteboard wrapper for mobile.
 *
 * @returns {JSX.Element}
 */
const WhiteboardWrapper = ({ className, collabDetails, collabServerUrl, localParticipantName }) => {
    const excalidrawRef = useRef(null);
    const excalidrawAPIRef = useRef(null);
    const collabAPIRef = useRef(null);
    const getExcalidrawAPI = useCallback(excalidrawAPI => {
        if (excalidrawAPIRef.current) {
            return;
        }
        excalidrawAPIRef.current = excalidrawAPI;
    }, []);
    const getCollabAPI = useCallback(collabAPI => {
        if (collabAPIRef.current) {
            return;
        }
        collabAPIRef.current = collabAPI;
        collabAPIRef.current.setUsername(localParticipantName);
    }, [localParticipantName]);
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: 'excalidraw-wrapper' },
            React.createElement(ExcalidrawApp, { collabDetails: collabDetails, collabServerUrl: collabServerUrl, detectScroll: true, excalidraw: {
                    isCollaborating: true,
                    langCode: i18next.language,
                    // @ts-ignore
                    ref: excalidrawRef,
                    theme: 'light',
                    UIOptions: WHITEBOARD_UI_OPTIONS
                }, getCollabAPI: getCollabAPI, getExcalidrawAPI: getExcalidrawAPI }))));
};
export default WhiteboardWrapper;
