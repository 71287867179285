import React from 'react';
import { connect } from 'react-redux';
import { createCalendarClickedEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { translate } from '../../base/i18n/functions';
import Icon from '../../base/icons/components/Icon';
import { IconCalendar } from '../../base/icons/svg';
import AbstractPage from '../../base/react/components/AbstractPage';
import Spinner from '../../base/ui/components/web/Spinner';
import { openSettingsDialog } from '../../settings/actions.web';
import { SETTINGS_TABS } from '../../settings/constants';
import { refreshCalendar } from '../actions.web';
import { ERRORS } from '../constants';
import CalendarListContent from './CalendarListContent.web';
/**
 * Component to display a list of events from the user's calendar.
 */
class CalendarList extends AbstractPage {
    /**
     * Initializes a new {@code CalendarList} instance.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        // Bind event handlers so they are only bound once per instance.
        this._getRenderListEmptyComponent
            = this._getRenderListEmptyComponent.bind(this);
        this._onOpenSettings = this._onOpenSettings.bind(this);
        this._onKeyPressOpenSettings = this._onKeyPressOpenSettings.bind(this);
        this._onRefreshEvents = this._onRefreshEvents.bind(this);
    }
    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { disabled } = this.props;
        return (CalendarListContent
            ? React.createElement(CalendarListContent, { disabled: Boolean(disabled), listEmptyComponent: this._getRenderListEmptyComponent() })
            : null);
    }
    /**
     * Returns a component for showing the error message related to calendar
     * sync.
     *
     * @private
     * @returns {React$Component}
     */
    _getErrorMessage() {
        const { _calendarError = { error: undefined }, t } = this.props;
        let errorMessageKey = 'calendarSync.error.generic';
        let showRefreshButton = true;
        let showSettingsButton = true;
        if (_calendarError.error === ERRORS.GOOGLE_APP_MISCONFIGURED) {
            errorMessageKey = 'calendarSync.error.appConfiguration';
            showRefreshButton = false;
            showSettingsButton = false;
        }
        else if (_calendarError.error === ERRORS.AUTH_FAILED) {
            errorMessageKey = 'calendarSync.error.notSignedIn';
            showRefreshButton = false;
        }
        return (React.createElement("div", { className: 'meetings-list-empty' },
            React.createElement("p", { className: 'description' }, t(errorMessageKey)),
            React.createElement("div", { className: 'calendar-action-buttons' },
                showSettingsButton
                    && React.createElement("div", { className: 'button', onClick: this._onOpenSettings }, t('calendarSync.permissionButton')),
                showRefreshButton
                    && React.createElement("div", { className: 'button', onClick: this._onRefreshEvents }, t('calendarSync.refresh')))));
    }
    /**
     * Returns a list empty component if a custom one has to be rendered instead
     * of the default one in the {@link NavigateSectionList}.
     *
     * @private
     * @returns {React$Component}
     */
    _getRenderListEmptyComponent() {
        const { _calendarError, _hasIntegrationSelected, _hasLoadedEvents, t } = this.props;
        if (_calendarError) {
            return this._getErrorMessage();
        }
        else if (_hasIntegrationSelected && _hasLoadedEvents) {
            return (React.createElement("div", { className: 'meetings-list-empty' },
                React.createElement("p", { className: 'description' }, t('calendarSync.noEvents')),
                React.createElement("div", { className: 'button', onClick: this._onRefreshEvents }, t('calendarSync.refresh'))));
        }
        else if (_hasIntegrationSelected && !_hasLoadedEvents) {
            return (React.createElement("div", { className: 'meetings-list-empty' },
                React.createElement(Spinner, null)));
        }
        return (React.createElement("div", { className: 'meetings-list-empty' },
            React.createElement("div", { className: 'meetings-list-empty-image' },
                React.createElement("img", { alt: t('welcomepage.logo.calendar'), src: './images/calendar.svg' })),
            React.createElement("div", { className: 'description' }, t('welcomepage.connectCalendarText', {
                app: interfaceConfig.APP_NAME,
                provider: interfaceConfig.PROVIDER_NAME
            })),
            React.createElement("div", { className: 'meetings-list-empty-button', onClick: this._onOpenSettings, onKeyPress: this._onKeyPressOpenSettings, role: 'button', tabIndex: 0 },
                React.createElement(Icon, { className: 'meetings-list-empty-icon', src: IconCalendar }),
                React.createElement("span", null, t('welcomepage.connectCalendarButton')))));
    }
    /**
     * Opens {@code SettingsDialog}.
     *
     * @private
     * @returns {void}
     */
    _onOpenSettings() {
        sendAnalytics(createCalendarClickedEvent('connect'));
        this.props.dispatch(openSettingsDialog(SETTINGS_TABS.CALENDAR));
    }
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onKeyPressOpenSettings(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            this._onOpenSettings();
        }
    }
    /**
     * Gets an updated list of calendar events.
     *
     * @private
     * @returns {void}
     */
    _onRefreshEvents() {
        this.props.dispatch(refreshCalendar(true));
    }
}
/**
 * Maps (parts of) the Redux state to the associated props for the
 * {@code CalendarList} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {{
 *     _calendarError: Object,
 *     _hasIntegrationSelected: boolean,
 *     _hasLoadedEvents: boolean
 * }}
 */
function _mapStateToProps(state) {
    const { error, events, integrationType, isLoadingEvents } = state['features/calendar-sync'];
    return {
        _calendarError: error,
        _hasIntegrationSelected: Boolean(integrationType),
        _hasLoadedEvents: Boolean(events) || !isLoadingEvents
    };
}
export default translate(connect(_mapStateToProps)(CalendarList));
