import React from 'react';
import Icon from '../../../icons/components/Icon';
import Popover from '../../../popover/components/Popover.web';
/**
 * Displays the `ToolboxButtonWithIcon` component.
 *
 * @param {Object} props - Component's props.
 * @returns {ReactElement}
 */
export default function ToolboxButtonWithPopup(props) {
    const { ariaLabel, children, icon, iconDisabled, onPopoverClose, onPopoverOpen, popoverContent, styles, trigger, visible } = props;
    if (!icon) {
        return (React.createElement("div", { className: 'settings-button-container', style: styles },
            React.createElement(Popover, { content: popoverContent, headingLabel: ariaLabel, onPopoverClose: onPopoverClose, onPopoverOpen: onPopoverOpen, position: 'top', trigger: trigger, visible: visible }, children)));
    }
    return (React.createElement("div", { className: 'settings-button-container', style: styles },
        children,
        React.createElement("div", { className: 'settings-button-small-icon-container' },
            React.createElement(Popover, { content: popoverContent, headingLabel: ariaLabel, onPopoverClose: onPopoverClose, onPopoverOpen: onPopoverOpen, position: 'top', visible: visible },
                React.createElement(Icon, { alt: ariaLabel, className: `settings-button-small-icon ${iconDisabled
                        ? 'settings-button-small-icon--disabled'
                        : ''}`, size: 16, src: icon })))));
}
