import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../../base/i18n/functions';
import { withPixelLineHeight } from '../../../../base/styles/functions.web';
import { UPGRADE_OPTIONS_LINK, UPGRADE_OPTIONS_TEXT } from '../../../constants';
const useStyles = makeStyles()(theme => {
    return {
        limitContainer: {
            backgroundColor: theme.palette.warning01,
            borderRadius: '6px',
            padding: '8px 16px'
        },
        limitInfo: {
            color: theme.palette.text.primary,
            ...withPixelLineHeight(theme.typography.bodyShortRegular)
        },
        link: {
            color: `${theme.palette.text.primary} !important`,
            fontWeight: 'bold',
            textDecoration: 'underline'
        }
    };
});
/**
 * Component that displays a message when the dial in limit is reached.
 * * @param {Function} t - Function which translate strings.
 *
 * @returns {ReactElement}
 */
const DialInLimit = ({ t }) => {
    const { classes } = useStyles();
    return (React.createElement("div", { className: classes.limitContainer },
        React.createElement("span", { className: classes.limitInfo },
            React.createElement("b", null, `${t('info.dialInNumber')} `),
            `${t('info.reachedLimit')} `,
            `${t('info.upgradeOptions')} `,
            React.createElement("a", { className: classes.link, href: UPGRADE_OPTIONS_LINK, rel: 'noopener noreferrer', target: '_blank' }, `${UPGRADE_OPTIONS_TEXT}`),
            ".")));
};
export default translate(DialInLimit);
