/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword } from '../../../../base/conference/actions';
import { isLocalParticipantModerator } from '../../../../base/participants/functions';
import { copyText } from '../../../../base/util/copyText.web';
import { LOCKED_LOCALLY } from '../../../../room-lock/constants';
import { NOTIFY_CLICK_MODE } from '../../../../toolbox/types';
import PasswordForm from './PasswordForm';
const DIGITS_ONLY = /^\d+$/;
const KEY = 'add-passcode';
/**
 * Component that handles the password manipulation from the invite dialog.
 *
 * @returns {React$Element<any>}
 */
function PasswordSection() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const canEditPassword = useSelector(isLocalParticipantModerator);
    const passwordNumberOfDigits = useSelector((state) => state['features/base/config'].roomPasswordNumberOfDigits);
    const conference = useSelector((state) => state['features/base/conference'].conference);
    const locked = useSelector((state) => state['features/base/conference'].locked);
    const password = useSelector((state) => state['features/base/conference'].password);
    const formRef = useRef(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const buttonsWithNotifyClick = useSelector((state) => state['features/toolbox'].buttonsWithNotifyClick);
    const [passwordEditEnabled, setPasswordEditEnabled] = useState(false);
    if (passwordEditEnabled && (password || locked)) {
        setPasswordEditEnabled(false);
    }
    const onPasswordSubmit = useCallback((enteredPassword) => {
        if (enteredPassword && passwordNumberOfDigits && !DIGITS_ONLY.test(enteredPassword)) {
            // Don't set the password.
            return;
        }
        dispatch(setPassword(conference, conference?.lock, enteredPassword));
    }, [dispatch, passwordNumberOfDigits, conference?.lock]);
    const onTogglePasswordEditState = useCallback(() => {
        if (typeof APP === 'undefined' || !buttonsWithNotifyClick?.size) {
            setPasswordEditEnabled(!passwordEditEnabled);
            return;
        }
        const notifyMode = buttonsWithNotifyClick?.get(KEY);
        if (notifyMode) {
            APP.API.notifyToolbarButtonClicked(KEY, notifyMode === NOTIFY_CLICK_MODE.PREVENT_AND_NOTIFY);
        }
        if (!notifyMode || notifyMode === NOTIFY_CLICK_MODE.ONLY_NOTIFY) {
            setPasswordEditEnabled(!passwordEditEnabled);
        }
    }, [buttonsWithNotifyClick, setPasswordEditEnabled, passwordEditEnabled]);
    const onPasswordSave = useCallback(() => {
        if (formRef.current) {
            // @ts-ignore
            const { value } = formRef.current.querySelector('div > input');
            if (value) {
                onPasswordSubmit(value);
            }
        }
    }, [formRef.current, onPasswordSubmit]);
    const onPasswordRemove = useCallback(() => {
        onPasswordSubmit('');
    }, [onPasswordSubmit]);
    const onPasswordCopy = useCallback(() => {
        copyText(password ?? '');
    }, [password]);
    const onPasswordShow = useCallback(() => {
        setPasswordVisible(true);
    }, [setPasswordVisible]);
    const onPasswordHide = useCallback(() => {
        setPasswordVisible(false);
    }, [setPasswordVisible]);
    let actions = null;
    if (canEditPassword) {
        if (passwordEditEnabled) {
            actions = (React.createElement(React.Fragment, null,
                React.createElement("button", { className: 'as-link', onClick: onTogglePasswordEditState, type: 'button' },
                    t('dialog.Cancel'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")")),
                React.createElement("button", { className: 'as-link', onClick: onPasswordSave, type: 'button' },
                    t('dialog.add'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")"))));
        }
        else if (locked) {
            actions = (React.createElement(React.Fragment, null,
                React.createElement("button", { className: 'remove-password as-link', onClick: onPasswordRemove, type: 'button' },
                    t('dialog.Remove'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")")),
                // There are cases like lobby and grant moderator when password is not available
                password ? React.createElement(React.Fragment, null,
                    React.createElement("button", { className: 'copy-password as-link', onClick: onPasswordCopy, type: 'button' },
                        t('dialog.copy'),
                        React.createElement("span", { className: 'sr-only' },
                            "(",
                            t('dialog.password'),
                            ")"))) : null,
                locked === LOCKED_LOCALLY && (React.createElement("button", { className: 'as-link', onClick: passwordVisible ? onPasswordHide : onPasswordShow, type: 'button' },
                    t(passwordVisible ? 'dialog.hide' : 'dialog.show'),
                    React.createElement("span", { className: 'sr-only' },
                        "(",
                        t('dialog.password'),
                        ")")))));
        }
        else {
            actions = (React.createElement("button", { className: 'add-password as-link', onClick: onTogglePasswordEditState, type: 'button' }, t('info.addPassword')));
        }
    }
    return (React.createElement("div", { className: 'security-dialog password-section' },
        React.createElement("p", { className: 'description' }, t(canEditPassword ? 'security.about' : 'security.aboutReadOnly')),
        React.createElement("div", { className: 'security-dialog password' },
            React.createElement("div", { className: 'info-dialog info-dialog-column info-dialog-password', ref: formRef },
                React.createElement(PasswordForm, { editEnabled: passwordEditEnabled, locked: locked, onSubmit: onPasswordSubmit, password: password, passwordNumberOfDigits: passwordNumberOfDigits, visible: passwordVisible })),
            React.createElement("div", { className: 'security-dialog password-actions' }, actions))));
}
export default PasswordSection;
