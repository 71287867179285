/**
 * Whiteboard statuses used to raise the notification when it's changed.
 *
 * @enum
 */
export var WhiteboardStatus;
(function (WhiteboardStatus) {
    WhiteboardStatus["FORBIDDEN"] = "FORBIDDEN";
    WhiteboardStatus["HIDDEN"] = "HIDDEN";
    WhiteboardStatus["INSTANTIATED"] = "INSTANTIATED";
    WhiteboardStatus["RESET"] = "RESET";
    WhiteboardStatus["SHOWN"] = "SHOWN";
})(WhiteboardStatus || (WhiteboardStatus = {}));
