import React from 'react';
import { useTranslation } from 'react-i18next';
import { createLocalizedTime } from '../timeFunctions';
/**
 * React component for displaying total time elapsed. Converts a total count of
 * milliseconds into a more humanized form: "# hours, # minutes, # seconds".
 * With a time of 0, "0s" will be displayed.
 *
 * @augments Component
 */
const TimeElapsed = ({ time }) => {
    const { t } = useTranslation();
    const timeElapsed = createLocalizedTime(time, t);
    return (React.createElement("span", null, timeElapsed));
};
export default TimeElapsed;
