import React from 'react';
/**
 *  Label for the dialogs.
 *
 *  @returns {ReactElement}
 */
function Label({ children, className, number, onClick }) {
    const containerClass = className
        ? `prejoin-dialog-label ${className}`
        : 'prejoin-dialog-label';
    return (React.createElement("div", { className: containerClass, onClick: onClick },
        number && React.createElement("div", { className: 'prejoin-dialog-label-num' }, number),
        React.createElement("span", null, children)));
}
export default Label;
