import ReducerRegistry from '../base/redux/ReducerRegistry';
import { CHANGE_VOTE, CLEAR_POLLS, EDIT_POLL, RECEIVE_ANSWER, RECEIVE_POLL, REGISTER_VOTE, RESET_NB_UNREAD_POLLS, RETRACT_VOTE, SAVE_POLL } from './actionTypes';
const INITIAL_STATE = {
    polls: {},
    // Number of not read message
    nbUnreadPolls: 0
};
ReducerRegistry.register('features/polls', (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_VOTE: {
            const { pollId, value } = action;
            return {
                ...state,
                polls: {
                    ...state.polls,
                    [pollId]: {
                        ...state.polls[pollId],
                        changingVote: value,
                        showResults: !value
                    }
                }
            };
        }
        case CLEAR_POLLS: {
            return {
                ...state,
                ...INITIAL_STATE
            };
        }
        // Reducer triggered when a poll is received or saved.
        case RECEIVE_POLL:
        case SAVE_POLL: {
            return {
                ...state,
                polls: {
                    ...state.polls,
                    [action.pollId]: action.poll
                },
                nbUnreadPolls: state.nbUnreadPolls + 1
            };
        }
        // Reducer triggered when an answer is received
        // The answer is added  to an existing poll
        case RECEIVE_ANSWER: {
            const { pollId, answer } = action;
            // if the poll doesn't exist
            if (!(pollId in state.polls)) {
                console.warn('requested poll does not exist: pollId ', pollId);
                return state;
            }
            // if the poll exists, we update it with the incoming answer
            const newAnswers = state.polls[pollId].answers
                .map(_answer => {
                // checking if the voters is an array for supporting old structure model
                const answerVoters = _answer.voters
                    ? _answer.voters.length
                        ? [..._answer.voters] : Object.keys(_answer.voters) : [];
                return {
                    name: _answer.name,
                    voters: answerVoters
                };
            });
            for (let i = 0; i < newAnswers.length; i++) {
                // if the answer was chosen, we add the senderId to the array of voters of this answer
                const voters = newAnswers[i].voters;
                const index = voters.indexOf(answer.voterId);
                if (answer.answers[i]) {
                    if (index === -1) {
                        voters.push(answer.voterId);
                    }
                }
                else if (index > -1) {
                    voters.splice(index, 1);
                }
            }
            // finally we update the state by returning the updated poll
            return {
                ...state,
                polls: {
                    ...state.polls,
                    [pollId]: {
                        ...state.polls[pollId],
                        answers: newAnswers
                    }
                }
            };
        }
        case REGISTER_VOTE: {
            const { answers, pollId } = action;
            return {
                ...state,
                polls: {
                    ...state.polls,
                    [pollId]: {
                        ...state.polls[pollId],
                        changingVote: false,
                        lastVote: answers,
                        showResults: true
                    }
                }
            };
        }
        case RETRACT_VOTE: {
            const { pollId } = action;
            return {
                ...state,
                polls: {
                    ...state.polls,
                    [pollId]: {
                        ...state.polls[pollId],
                        showResults: false
                    }
                }
            };
        }
        case RESET_NB_UNREAD_POLLS: {
            return {
                ...state,
                nbUnreadPolls: 0
            };
        }
        case EDIT_POLL: {
            return {
                ...state,
                polls: {
                    ...state.polls,
                    [action.pollId]: {
                        ...state.polls[action.pollId],
                        editing: action.editing
                    }
                }
            };
        }
        default:
            return state;
    }
});
