import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import Popover from '../../../../base/popover/components/Popover.web';
import { withPixelLineHeight } from '../../../../base/styles/functions.web';
import { setDialOutCountry, setDialOutNumber } from '../../../actions.web';
import { getDialOutCountry, getDialOutNumber } from '../../../functions';
import { getCountryFromDialCodeText } from '../../../utils';
import CountryDropDown from './CountryDropdown';
import CountrySelector from './CountrySelector';
const PREFIX_REG = /^(00)|\+/;
const useStyles = makeStyles()(theme => {
    return {
        container: {
            border: 0,
            borderRadius: theme.shape.borderRadius,
            display: 'flex',
            backgroundColor: theme.palette.ui03
        },
        input: {
            padding: '0 4px',
            margin: 0,
            border: 0,
            background: 'transparent',
            color: theme.palette.text01,
            flexGrow: 1,
            ...withPixelLineHeight(theme.typography.bodyShortRegular)
        }
    };
});
const CountryPicker = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef(null);
    const { classes } = useStyles();
    useEffect(() => {
        inputRef.current?.focus();
    }, []);
    const onChange = ({ target: { value: newValue } }) => {
        if (PREFIX_REG.test(newValue)) {
            const textWithDialCode = newValue.replace(PREFIX_REG, '');
            if (textWithDialCode.length >= 4) {
                const country = getCountryFromDialCodeText(textWithDialCode);
                if (country) {
                    const rest = textWithDialCode.replace(country.dialCode, '');
                    props.setDialOutCountry(country);
                    props.setDialOutNumber(rest);
                    return;
                }
            }
        }
        props.setDialOutNumber(newValue);
    };
    const onCountrySelectorClick = () => {
        setIsOpen(open => !open);
    };
    const onDropdownClose = () => {
        setIsOpen(false);
    };
    const onEntryClick = (country) => {
        props.setDialOutCountry(country);
        onDropdownClose();
    };
    const onKeyPress = (e) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            props.onSubmit();
        }
    };
    return (
    /* eslint-disable react/jsx-no-bind */
    React.createElement(Popover, { content: React.createElement(CountryDropDown, { onEntryClick: onEntryClick }), onPopoverClose: onDropdownClose, position: 'bottom', trigger: 'click', visible: isOpen },
        React.createElement("div", { className: classes.container },
            React.createElement(CountrySelector, { country: props.dialOutCountry, onClick: onCountrySelectorClick }),
            React.createElement("input", { className: classes.input, onChange: onChange, onKeyPress: onKeyPress, ref: inputRef, value: props.dialOutNumber }))));
};
/**
 * Maps (parts of) the redux state to the React {@code Component} props.
 *
 * @param {Object} state - The redux state.
 * @returns {IProps}
 */
function mapStateToProps(state) {
    return {
        dialOutCountry: getDialOutCountry(state),
        dialOutNumber: getDialOutNumber(state)
    };
}
/**
 * Maps redux actions to the props of the component.
 *
 * @type {{
 *     setDialOutCountry: Function,
 *     setDialOutNumber: Function
 * }}
 */
const mapDispatchToProps = {
    setDialOutCountry,
    setDialOutNumber
};
export default connect(mapStateToProps, mapDispatchToProps)(CountryPicker);
