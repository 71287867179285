import StateListenerRegistry from '../redux/StateListenerRegistry';
/**
 * Notifies when the local audio mute state changes.
 */
StateListenerRegistry.register(
/* selector */ (state) => state['features/base/media'].audio.muted, 
/* listener */ (muted, store, previousMuted) => {
    if (typeof APP !== 'object') {
        return;
    }
    if (muted !== previousMuted) {
        APP.API.notifyAudioMutedStatusChanged(muted);
    }
});
