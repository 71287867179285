import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../../base/i18n/functions';
import { withPixelLineHeight } from '../../../../base/styles/functions.web';
import { _formatConferenceIDPin } from '../../../_utils';
const useStyles = makeStyles()((theme) => {
    return {
        container: {
            marginTop: 32,
            maxWidth: 310,
            padding: '16px 12px',
            background: theme.palette.ui02,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 6,
            '& *': {
                userSelect: 'text'
            }
        },
        confNameLabel: {
            ...withPixelLineHeight(theme.typography.heading6),
            marginBottom: 18,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        descriptionLabel: {
            ...withPixelLineHeight(theme.typography.bodyShortRegularLarge),
            marginBottom: 18
        },
        separator: {
            width: '100%',
            height: 1,
            background: theme.palette.ui04,
            marginBottom: 18
        },
        pinLabel: {
            ...withPixelLineHeight(theme.typography.heading6)
        }
    };
});
const ConferenceID = ({ conferenceID, t }) => {
    const { classes: styles } = useStyles();
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.descriptionLabel }, t('info.dialANumber')),
        React.createElement("div", { className: styles.separator }),
        React.createElement("div", { className: styles.pinLabel }, `${t('info.dialInConferenceID')} ${_formatConferenceIDPin(conferenceID ?? '')}`)));
};
export default translate(ConferenceID);
