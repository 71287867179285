import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
/**
 * Higher Order Component taking in a concrete PollsPane component and
 * augmenting it with state/behavior common to both web and native implementations.
 *
 * @param {React.AbstractComponent} Component - The concrete component.
 * @returns {React.AbstractComponent}
 */
const AbstractPollsPane = (Component) => () => {
    const [createMode, setCreateMode] = useState(false);
    const onCreate = () => {
        setCreateMode(true);
    };
    const { t } = useTranslation();
    return (React.createElement(Component, { createMode: createMode, 
        /* eslint-disable react/jsx-no-bind */
        onCreate: onCreate, setCreateMode: setCreateMode, t: t }));
};
export default AbstractPollsPane;
