import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { withPixelLineHeight } from '../../../styles/functions.web';
import Button from '../../../ui/components/web/Button';
const useStyles = makeStyles()(theme => {
    return {
        dialog: {
            backgroundColor: theme.palette.ui01,
            border: `1px solid ${theme.palette.ui04}`,
            borderRadius: `${Number(theme.shape.borderRadius)}px`,
            boxShadow: '0px 1px 2px rgba(41, 41, 41, 0.25)',
            color: theme.palette.text01,
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            padding: `${theme.spacing(3)} 10`,
            '& .retry-button': {
                margin: '16px auto 0 auto'
            }
        }
    };
});
/**
 * Inline dialog that represents a failure and allows a retry.
 *
 * @returns {Element}
 */
const InlineDialogFailure = ({ onRetry, showSupportLink }) => {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const supportLink = interfaceConfig.SUPPORT_URL;
    const supportString = t('inlineDialogFailure.supportMsg');
    const supportLinkElem = supportLink && showSupportLink
        ? (React.createElement("div", null,
            React.createElement("span", null, supportString.padEnd(supportString.length + 1)),
            React.createElement("span", null,
                React.createElement("a", { href: supportLink, rel: 'noopener noreferrer', target: '_blank' }, t('inlineDialogFailure.support'))),
            React.createElement("span", null, ".")))
        : null;
    return (React.createElement("div", { className: classes.dialog },
        React.createElement("div", null, t('inlineDialogFailure.msg')),
        supportLinkElem,
        React.createElement(Button, { className: 'retry-button', label: t('inlineDialogFailure.retry'), onClick: onRetry })));
};
export default InlineDialogFailure;
