import ReducerRegistry from '../base/redux/ReducerRegistry';
import { RESET_WHITEBOARD, SETUP_WHITEBOARD } from './actionTypes';
const DEFAULT_STATE = {
    isOpen: false,
    collabDetails: undefined,
    collabServerUrl: undefined
};
ReducerRegistry.register('features/whiteboard', (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SETUP_WHITEBOARD: {
            return {
                ...state,
                isOpen: true,
                collabDetails: action.collabDetails,
                collabServerUrl: action.collabServerUrl
            };
        }
        case RESET_WHITEBOARD:
            return DEFAULT_STATE;
    }
    return state;
});
