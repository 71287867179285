import React, { Component } from 'react';
import { translate } from '../../base/i18n/functions';
import Icon from '../../base/icons/components/Icon';
import { IconPlus } from '../../base/icons/svg';
import Tooltip from '../../base/tooltip/components/Tooltip';
/**
 * A React Component for joining an existing calendar meeting.
 *
 * @augments Component
 */
class JoinButton extends Component {
    /**
     * Initializes a new {@code JoinButton} instance.
     *
     * @param {*} props - The read-only properties with which the new instance
     * is to be initialized.
     */
    constructor(props) {
        super(props);
        // Bind event handler so it is only bound once for every instance.
        this._onClick = this._onClick.bind(this);
        this._onKeyPress = this._onKeyPress.bind(this);
    }
    /**
     * Implements React's {@link Component#render}.
     *
     * @inheritdoc
     */
    render() {
        const { t } = this.props;
        return (React.createElement(Tooltip, { content: t('calendarSync.joinTooltip') },
            React.createElement("div", { className: 'button join-button', onClick: this._onClick, onKeyPress: this._onKeyPress, role: 'button' },
                React.createElement(Icon, { size: '14', src: IconPlus }))));
    }
    /**
     * Callback invoked when the component is clicked.
     *
     * @param {Object} event - The DOM click event.
     * @private
     * @returns {void}
     */
    _onClick(event) {
        this.props.onPress(event, this.props.url);
    }
    /**
     * KeyPress handler for accessibility.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    _onKeyPress(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            this._onClick();
        }
    }
}
export default translate(JoinButton);
