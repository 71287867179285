import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createDeepLinkingPageEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
/**
 * React component representing no mobile app page.
 *
 * @class NoMobileApp
 */
class NoMobileApp extends Component {
    /**
     * Implements the Component's componentDidMount method.
     *
     * @inheritdoc
     */
    componentDidMount() {
        sendAnalytics(createDeepLinkingPageEvent('displayed', 'noMobileApp', { isMobileBrowser: true }));
    }
    /**
     * Renders the component.
     *
     * @returns {ReactElement}
     */
    render() {
        const ns = 'no-mobile-app';
        const { desktop } = this.props._deeplinkingCfg;
        const { appName } = desktop ?? {};
        return (React.createElement("div", { className: ns },
            React.createElement("h2", { className: `${ns}__title` }, "Video chat isn't available on mobile."),
            React.createElement("p", { className: `${ns}__description` },
                "Please use ",
                appName,
                " on desktop to join calls.")));
    }
}
/**
 * Maps (parts of) the Redux state to the associated props for the
 * {@code NoMobileApp} component.
 *
 * @param {Object} state - The Redux state.
 * @private
 * @returns {IProps}
 */
function _mapStateToProps(state) {
    return {
        _deeplinkingCfg: state['features/base/config'].deeplinking || {}
    };
}
export default connect(_mapStateToProps)(NoMobileApp);
