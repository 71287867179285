import { Component } from 'react';
import { extractYoutubeIdOrURL } from '../functions';
/**
 * Implements an abstract class for {@code SharedVideoDialog}.
 */
export default class AbstractSharedVideoDialog extends Component {
    /**
     * Instantiates a new component.
     *
     * @inheritdoc
     */
    constructor(props) {
        super(props);
        this._onSetVideoLink = this._onSetVideoLink.bind(this);
    }
    /**
     * Validates the entered video link by extracting the id and dispatches it.
     *
     * It returns a boolean to comply the Dialog behaviour:
     *     {@code true} - the dialog should be closed.
     *     {@code false} - the dialog should be left open.
     *
    * @param {string} link - The entered video link.
     * @returns {boolean}
     */
    _onSetVideoLink(link) {
        const { onPostSubmit } = this.props;
        const id = extractYoutubeIdOrURL(link);
        if (!id) {
            return false;
        }
        onPostSubmit(id);
        return true;
    }
}
