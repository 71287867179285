import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Icon from '../../../base/icons/components/Icon';
import { IconCloseLarge } from '../../../base/icons/svg';
import { toggleChat } from '../../actions.web';
/**
 * Custom header of the {@code ChatDialog}.
 *
 * @returns {React$Element<any>}
 */
function ChatHeader({ className, isPollsEnabled }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onCancel = useCallback(() => {
        dispatch(toggleChat());
    }, []);
    const onKeyPressHandler = useCallback(e => {
        if (onCancel && (e.key === ' ' || e.key === 'Enter')) {
            e.preventDefault();
            onCancel();
        }
    }, []);
    return (React.createElement("div", { className: className || 'chat-dialog-header' },
        React.createElement("span", { "aria-level": 1, role: 'heading' }, t(isPollsEnabled ? 'chat.titleWithPolls' : 'chat.title')),
        React.createElement(Icon, { ariaLabel: t('toolbar.closeChat'), onClick: onCancel, onKeyPress: onKeyPressHandler, role: 'button', src: IconCloseLarge, tabIndex: 0 })));
}
export default ChatHeader;
