import React, { Component } from 'react';
import ReactLinkify from 'react-linkify';
import { formatURLText } from '../../functions';
/**
 * Implements a react wrapper for the react-linkify component.
 */
export default class Linkify extends Component {
    /**
     * Implements {@Component#render}.
     *
     * @inheritdoc
     */
    render() {
        return (React.createElement(ReactLinkify, { componentDecorator: this._componentDecorator }, this.props.children));
    }
    /**
     * Implements a component decorator for react-linkify.
     *
     * @param {string} decoratedHref - The href src.
     * @param {string} decoratedText - The link text.
     * @param {string} key - The component key.
     * @returns {React$Node}
     */
    _componentDecorator(decoratedHref, decoratedText, key) {
        return (React.createElement("a", { href: decoratedHref, key: key, rel: 'noopener noreferrer', target: '_blank' }, formatURLText(decoratedText)));
    }
}
