import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { getParticipantById, getParticipantDisplayName } from '../../../base/participants/functions';
import { updateSettings } from '../../../base/settings/actions';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import Tooltip from '../../../base/tooltip/components/Tooltip';
import { getIndicatorsTooltipPosition } from '../../../filmstrip/functions.web';
import { appendSuffix } from '../../functions';
const useStyles = makeStyles()(theme => {
    return {
        displayName: {
            ...withPixelLineHeight(theme.typography.labelBold),
            color: theme.palette.text01,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        editDisplayName: {
            outline: 'none',
            border: 'none',
            background: 'none',
            boxShadow: 'none',
            padding: 0,
            ...withPixelLineHeight(theme.typography.labelBold),
            color: theme.palette.text01
        }
    };
});
const DisplayName = ({ allowEditing, displayNameSuffix, elementID, participantID, thumbnailType }) => {
    const { classes } = useStyles();
    const configuredDisplayName = useSelector((state) => getParticipantById(state, participantID))?.name ?? '';
    const nameToDisplay = useSelector((state) => getParticipantDisplayName(state, participantID));
    const [editDisplayNameValue, setEditDisplayNameValue] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const nameInputRef = useRef(null);
    useEffect(() => {
        if (isEditing && nameInputRef.current) {
            nameInputRef.current.select();
        }
    }, [isEditing]);
    const onClick = useCallback((e) => {
        e.stopPropagation();
    }, []);
    const onChange = useCallback((event) => {
        setEditDisplayNameValue(event.target.value);
    }, []);
    const onSubmit = useCallback(() => {
        dispatch(updateSettings({
            displayName: editDisplayNameValue
        }));
        setEditDisplayNameValue('');
        setIsEditing(false);
        nameInputRef.current = null;
    }, [editDisplayNameValue, nameInputRef]);
    const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }, [onSubmit]);
    const onStartEditing = useCallback((e) => {
        if (allowEditing) {
            e.stopPropagation();
            setIsEditing(true);
            setEditDisplayNameValue(configuredDisplayName);
        }
    }, [allowEditing]);
    if (allowEditing && isEditing) {
        return (React.createElement("input", { autoFocus: true, className: classes.editDisplayName, id: 'editDisplayName', onBlur: onSubmit, onChange: onChange, onClick: onClick, onKeyDown: onKeyDown, placeholder: t('defaultNickname'), ref: nameInputRef, spellCheck: 'false', type: 'text', value: editDisplayNameValue }));
    }
    return (React.createElement(Tooltip, { content: appendSuffix(nameToDisplay, displayNameSuffix), position: getIndicatorsTooltipPosition(thumbnailType) },
        React.createElement("span", { className: `displayname ${classes.displayName}`, id: elementID, onClick: onStartEditing }, appendSuffix(nameToDisplay, displayNameSuffix))));
};
export default DisplayName;
