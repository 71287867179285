import React from 'react';
import AbstractButton from '../../../base/toolbox/components/AbstractButton';
/**
 * Component that renders a custom toolbox button.
 *
 * @returns {Component}
 */
class CustomOptionButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.iconSrc = this.props.icon;
        this.id = this.props.id;
        this.text = this.props.text;
        this.backgroundColor = this.props.backgroundColor;
        this.accessibilityLabel = this.text;
        /**
         * Custom icon component.
         *
         * @param {any} props - Icon's props.
         * @returns {img}
         */
        this.icon = (props) => (React.createElement("img", { src: this.iconSrc, ...props }));
        this.label = this.text;
        this.tooltip = this.text;
    }
}
export default CustomOptionButton;
