import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translate } from '../../../../base/i18n/functions';
import Icon from '../../../../base/icons/components/Icon';
import { IconCheck, IconCopy } from '../../../../base/icons/svg';
import Tooltip from '../../../../base/tooltip/components/Tooltip';
import { copyText } from '../../../../base/util/copyText.web';
import { showSuccessNotification } from '../../../../notifications/actions';
import { NOTIFICATION_TIMEOUT_TYPE } from '../../../../notifications/constants';
import { _formatConferenceIDPin } from '../../../_utils';
let mounted;
/**
 * Component responsible for displaying a telephone number and
 * conference ID for dialing into a conference and copying them to clipboard.
 *
 * @returns {ReactNode}
 */
function DialInNumber({ conferenceID, phoneNumber, t }) {
    const dispatch = useDispatch();
    const [isClicked, setIsClicked] = useState(false);
    const dialInLabel = t('info.dialInNumber');
    const passcode = t('info.dialInConferenceID');
    const conferenceIDPin = `${_formatConferenceIDPin(conferenceID)}#`;
    const textToCopy = `${dialInLabel} ${phoneNumber} ${passcode} ${conferenceIDPin}`;
    useEffect(() => {
        mounted = true;
        return () => {
            mounted = false;
        };
    }, []);
    /**
     * Copies the conference ID and phone number to the clipboard.
     *
     * @returns {void}
    */
    function _onCopyText() {
        copyText(textToCopy);
        dispatch(showSuccessNotification({
            titleKey: 'dialog.copied'
        }, NOTIFICATION_TIMEOUT_TYPE.SHORT));
        setIsClicked(true);
        setTimeout(() => {
            // avoid: Can't perform a React state update on an unmounted component
            if (mounted) {
                setIsClicked(false);
            }
        }, 2500);
    }
    /**
     * Copies the conference invitation to the clipboard.
     *
     * @param {Object} e - The key event to handle.
     *
     * @returns {void}
     */
    function _onCopyTextKeyPress(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            _onCopyText();
        }
    }
    /**
     * Renders section that shows the phone number and conference ID
     * and give user the ability to copy them to the clipboard.
     *
     * @returns {ReactNode}
     */
    return (React.createElement("div", { className: 'dial-in-number' },
        React.createElement("p", null,
            React.createElement("span", { className: 'phone-number' },
                React.createElement("span", { className: 'info-label' }, t('info.dialInNumber')),
                React.createElement("span", { className: 'spacer' }, "\u00A0"),
                React.createElement("span", { className: 'info-value' }, phoneNumber)),
            React.createElement("br", null),
            React.createElement("span", { className: 'conference-id' },
                React.createElement("span", { className: 'info-label' }, t('info.dialInConferenceID')),
                React.createElement("span", { className: 'spacer' }, "\u00A0"),
                React.createElement("span", { className: 'info-value' }, `${_formatConferenceIDPin(conferenceID)}#`))),
        React.createElement(Tooltip, { content: t('info.copyNumber'), position: 'top' },
            React.createElement("button", { "aria-label": t('info.copyNumber'), className: 'dial-in-copy invisible-button', 
                // eslint-disable-next-line react/jsx-no-bind
                onClick: _onCopyText, 
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress: _onCopyTextKeyPress },
                React.createElement(Icon, { src: isClicked ? IconCheck : IconCopy })))));
}
export default translate(DialInNumber);
