import React from 'react';
import { useSelector } from 'react-redux';
import { getSecurityUiConfig } from '../../../../base/config/functions.any';
import { isLocalParticipantModerator } from '../../../../base/participants/functions';
import Dialog from '../../../../base/ui/components/web/Dialog';
import E2EESection from '../../../../e2ee/components/E2EESection';
import LobbySection from '../../../../lobby/components/web/LobbySection';
import { isEnablingLobbyAllowed } from '../../../../lobby/functions';
import PasswordSection from './PasswordSection';
/**
 * Component that renders the security options dialog.
 *
 * @returns {React$Element<any>}
 */
export default function SecurityDialog() {
    const e2eeSupported = useSelector((state) => state['features/base/conference'].e2eeSupported);
    const disableLobbyPassword = useSelector((state) => getSecurityUiConfig(state)?.disableLobbyPassword);
    const _isEnablingLobbyAllowed = useSelector(isEnablingLobbyAllowed);
    const isModerator = useSelector(isLocalParticipantModerator);
    const showE2ee = Boolean(e2eeSupported) && isModerator;
    return (React.createElement(Dialog, { cancel: { hidden: true }, ok: { hidden: true }, titleKey: 'security.title' },
        React.createElement("div", { className: 'security-dialog' },
            _isEnablingLobbyAllowed && React.createElement(LobbySection, null),
            !disableLobbyPassword && (React.createElement(React.Fragment, null,
                _isEnablingLobbyAllowed && React.createElement("div", { className: 'separator-line' }),
                React.createElement(PasswordSection, null))),
            showE2ee ? React.createElement(React.Fragment, null,
                (_isEnablingLobbyAllowed || !disableLobbyPassword) && React.createElement("div", { className: 'separator-line' }),
                React.createElement(E2EESection, null)) : null)));
}
