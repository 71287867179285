import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { translate } from '../../../base/i18n/functions';
import Message from '../../../base/react/components/web/Message';
import { withPixelLineHeight } from '../../../base/styles/functions.web';
import { getCanReplyToMessage, getFormattedTimestamp, getMessageText, getPrivateNoticeMessage } from '../../functions';
import PrivateMessageButton from './PrivateMessageButton';
const useStyles = makeStyles()((theme) => {
    return {
        chatMessageWrapper: {
            maxWidth: '100%'
        },
        chatMessage: {
            display: 'inline-flex',
            padding: '12px',
            backgroundColor: theme.palette.ui02,
            borderRadius: '4px 12px 12px 12px',
            maxWidth: '100%',
            marginTop: '4px',
            boxSizing: 'border-box',
            '&.privatemessage': {
                backgroundColor: theme.palette.support05
            },
            '&.local': {
                backgroundColor: theme.palette.ui04,
                borderRadius: '12px 4px 12px 12px',
                '&.privatemessage': {
                    backgroundColor: theme.palette.support05
                }
            },
            '&.error': {
                backgroundColor: theme.palette.actionDanger,
                borderRadius: 0,
                fontWeight: 100
            },
            '&.lobbymessage': {
                backgroundColor: theme.palette.support05
            }
        },
        replyWrapper: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            maxWidth: '100%'
        },
        messageContent: {
            maxWidth: '100%',
            overflow: 'hidden',
            flex: 1
        },
        replyButtonContainer: {
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%'
        },
        replyButton: {
            padding: '2px'
        },
        displayName: {
            ...withPixelLineHeight(theme.typography.labelBold),
            color: theme.palette.text02,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginBottom: theme.spacing(1)
        },
        userMessage: {
            ...withPixelLineHeight(theme.typography.bodyShortRegular),
            color: theme.palette.text01,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
        },
        privateMessageNotice: {
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text02,
            marginTop: theme.spacing(1)
        },
        timestamp: {
            ...withPixelLineHeight(theme.typography.labelRegular),
            color: theme.palette.text03,
            marginTop: theme.spacing(1)
        }
    };
});
/**
 * Renders a single chat message.
 *
 * @param {IProps} props - Component's props.
 * @returns {JSX}
 */
const ChatMessage = ({ canReply, knocking, message, showDisplayName, showTimestamp, type, t }) => {
    const { classes, cx } = useStyles();
    /**
     * Renders the display name of the sender.
     *
     * @returns {React$Element<*>}
     */
    function _renderDisplayName() {
        return (React.createElement("div", { "aria-hidden": true, className: cx('display-name', classes.displayName) }, message.displayName));
    }
    /**
     * Renders the message privacy notice.
     *
     * @returns {React$Element<*>}
     */
    function _renderPrivateNotice() {
        return (React.createElement("div", { className: classes.privateMessageNotice }, getPrivateNoticeMessage(message)));
    }
    /**
     * Renders the time at which the message was sent.
     *
     * @returns {React$Element<*>}
     */
    function _renderTimestamp() {
        return (React.createElement("div", { className: cx('timestamp', classes.timestamp) }, getFormattedTimestamp(message)));
    }
    return (React.createElement("div", { className: cx(classes.chatMessageWrapper, type), id: message.messageId, tabIndex: -1 },
        React.createElement("div", { className: cx('chatmessage', classes.chatMessage, type, message.privateMessage && 'privatemessage', message.lobbyChat && !knocking && 'lobbymessage') },
            React.createElement("div", { className: classes.replyWrapper },
                React.createElement("div", { className: cx('messagecontent', classes.messageContent) },
                    showDisplayName && _renderDisplayName(),
                    React.createElement("div", { className: cx('usermessage', classes.userMessage) },
                        React.createElement("span", { className: 'sr-only' }, message.displayName === message.recipient
                            ? t('chat.messageAccessibleTitleMe')
                            : t('chat.messageAccessibleTitle', { user: message.displayName })),
                        React.createElement(Message, { text: getMessageText(message) })),
                    (message.privateMessage || (message.lobbyChat && !knocking))
                        && _renderPrivateNotice()),
                canReply
                    && (React.createElement("div", { className: classes.replyButtonContainer },
                        React.createElement(PrivateMessageButton, { isLobbyMessage: message.lobbyChat, participantID: message.id }))))),
        showTimestamp && _renderTimestamp()));
};
/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function _mapStateToProps(state, { message }) {
    const { knocking } = state['features/lobby'];
    return {
        canReply: getCanReplyToMessage(state, message),
        knocking
    };
}
export default translate(connect(_mapStateToProps)(ChatMessage));
