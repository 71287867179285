import { batch, connect } from 'react-redux';
import { createToolbarEvent } from '../../analytics/AnalyticsEvents';
import { sendAnalytics } from '../../analytics/functions';
import { TILE_VIEW_ENABLED } from '../../base/flags/constants';
import { getFeatureFlag } from '../../base/flags/functions';
import { translate } from '../../base/i18n/functions';
import { IconTileView } from '../../base/icons/svg';
import AbstractButton from '../../base/toolbox/components/AbstractButton';
import { setOverflowMenuVisible } from '../../toolbox/actions';
import { setTileView } from '../actions';
import { shouldDisplayTileView } from '../functions';
import logger from '../logger';
/**
 * Component that renders a toolbar button for toggling the tile layout view.
 *
 * @augments AbstractButton
 */
class TileViewButton extends AbstractButton {
    constructor() {
        super(...arguments);
        this.accessibilityLabel = 'toolbar.accessibilityLabel.enterTileView';
        this.toggledAccessibilityLabel = 'toolbar.accessibilityLabel.exitTileView';
        this.icon = IconTileView;
        this.label = 'toolbar.enterTileView';
        this.toggledLabel = 'toolbar.exitTileView';
        this.tooltip = 'toolbar.tileViewToggle';
    }
    /**
     * Handles clicking / pressing the button.
     *
     * @override
     * @protected
     * @returns {void}
     */
    _handleClick() {
        const { _tileViewEnabled, dispatch } = this.props;
        const value = !_tileViewEnabled;
        sendAnalytics(createToolbarEvent('tileview.button', {
            'is_enabled': value
        }));
        logger.debug(`Tile view ${value ? 'enable' : 'disable'}`);
        batch(() => {
            dispatch(setTileView(value));
            navigator.product !== 'ReactNative' && dispatch(setOverflowMenuVisible(false));
        });
    }
    /**
     * Indicates whether this button is in toggled state or not.
     *
     * @override
     * @protected
     * @returns {boolean}
     */
    _isToggled() {
        return this.props._tileViewEnabled;
    }
}
/**
 * Maps (parts of) the redux state to the associated props for the
 * {@code TileViewButton} component.
 *
 * @param {Object} state - The Redux state.
 * @param {Object} ownProps - The properties explicitly passed to the component instance.
 * @returns {IProps}
 */
function _mapStateToProps(state, ownProps) {
    const enabled = getFeatureFlag(state, TILE_VIEW_ENABLED, true);
    const { visible = enabled } = ownProps;
    return {
        _tileViewEnabled: shouldDisplayTileView(state),
        visible
    };
}
export default translate(connect(_mapStateToProps)(TileViewButton));
