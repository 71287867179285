import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import BaseTheme from './BaseTheme.web';
/**
 * The theme provider for the web app.
 *
 * @param {Object} props - The props of the component.
 * @returns {React.ReactNode}
 */
function JitsiThemeProvider(props) {
    return (React.createElement(StyledEngineProvider, { injectFirst: true },
        React.createElement(ThemeProvider, { theme: props._theme }, props.children)));
}
/**
 * Maps part of the Redux state to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @returns {IProps}
 */
function _mapStateToProps(state) {
    const { muiBrandedTheme } = state['features/dynamic-branding'];
    return {
        _theme: muiBrandedTheme || BaseTheme
    };
}
export default connect(_mapStateToProps)(JitsiThemeProvider);
